import domains from '@/domains'
import { useSlug } from '@/hooks'
import { useLocation } from 'react-router-dom'

export function usePages() {
    const location = useLocation()
    const slug = useSlug()
    const isCustomDomain = domains[window.location.hostname] !== undefined

    const pages = {
        Landing: isCustomDomain ? '/' : `/${slug}`,
        ProcessSelection: isCustomDomain ? '/select' : `/${slug}/select`,
        ItemSelection: isCustomDomain ? '/order' : `/${slug}/order`,
        RefundMethod: isCustomDomain
            ? '/refund-method'
            : `/${slug}/refund-method`,
        ReturnAddress: isCustomDomain
            ? '/return-address'
            : `/${slug}/return-address`,
        ReturnMethod: isCustomDomain
            ? '/return-method'
            : `/${slug}/return-method`,
        ExchangeAddress: isCustomDomain
            ? '/exchange-address'
            : `/${slug}/exchange-address`,
        Confirmation: isCustomDomain ? '/confirm' : `/${slug}/confirm`,
        Success: isCustomDomain ? '/success' : `/${slug}/success`,
        SuccessTracking: isCustomDomain
            ? '/success-tracking'
            : `/${slug}/success-tracking`,
        Error: isCustomDomain ? '/error' : `/${slug}/error`,
        Tracking: isCustomDomain ? '/tracking' : `/${slug}/tracking`,
        TrackingReturnLabel: isCustomDomain
            ? '/tracking/return-label'
            : `/${slug}/tracking/return-label`,
        TrackingPickUp: isCustomDomain
            ? '/tracking/schedule-pickup'
            : `/${slug}/tracking/schedule-pickup`,
        TrackingDropOff: isCustomDomain
            ? '/tracking/drop-off'
            : `/${slug}/tracking/drop-off`,
        TrackingCompensation: isCustomDomain
            ? '/tracking/compensation'
            : `/${slug}/tracking/compensation`,
        TrackingReturnDetails: isCustomDomain
            ? '/tracking/return-details'
            : `/${slug}/tracking/return-details`,
        TrackingUploadDocuments: isCustomDomain
            ? '/tracking/documents'
            : `/${slug}/tracking/documents`
    }

    const findCurrentPage = () => {
        switch (location.pathname) {
            case pages.Landing:
                return 'Landing'
            case pages.ItemSelection:
                return 'ItemSelection'
            case pages.RefundMethod:
                return 'RefundMethod'
            case pages.ReturnAddress:
                return 'ReturnAddress'
            case pages.ReturnMethod:
                return 'ReturnMethod'
            case pages.ExchangeAddress:
                return 'ExchangeAddress'
            case pages.Confirmation:
                return 'Confirmation'
            case pages.Success:
                return 'Success'
            case pages.SuccessTracking:
                return 'SuccessTracking'
            case pages.Error:
                return 'Error'
            case pages.Tracking:
                return 'Tracking'
            case pages.TrackingReturnLabel:
                return 'TrackingReturnLabel'
            case pages.TrackingPickUp:
                return 'TrackingPickUp'
            case pages.TrackingDropOff:
                return 'TrackingDropOff'
            case pages.TrackingCompensation:
                return 'TrackingCompensation'
            case pages.TrackingReturnDetails:
                return 'TrackingReturnDetails'
            case pages.ProcessSelection:
                return 'ProcessSelection'
            case pages.TrackingUploadDocuments:
                return 'TrackingUploadDocuments'
            default:
                return 'Landing'
        }
    }

    const currentPage = findCurrentPage()

    return { pages, currentPage }
}

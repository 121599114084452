import DOMPurify from 'dompurify'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@/redux/hooks'
import { useSearchParams } from 'react-router-dom'
import { notifyError } from '@/services/Sentry'

export function useTranslate() {
    const { i18n } = useTranslation()
    const copies = useAppSelector((store) => store.settingsApi.copies.response)
    const lang = i18n.language.toUpperCase()

    const [searchParams] = useSearchParams()

    function handleCopyKeys() {
        const showCopyKeys =
            searchParams.get('alojomora') === 'true' ||
            sessionStorage.getItem('alojomora') === 'true'
                ? true
                : false
        sessionStorage.setItem('alojomora', showCopyKeys.toString())
        return showCopyKeys
    }
    const showCopyKeys = handleCopyKeys()

    function replaceValues(
        input: string,
        values: { [key: string]: string }
    ): string {
        const regex = /{{(.*?)}}/g
        return input.replace(regex, (match: string, key: string) => {
            if (Object.prototype.hasOwnProperty.call(values, key)) {
                return values[key]
            } else {
                return ''
            }
        })
    }

    const translate = (key: string, values: { [key: string]: string } = {}) => {
        if (showCopyKeys) return key
        let copy = ''
        if (copies[lang + '-' + key]?.text !== undefined) {
            copy = copies[lang + '-' + key].text ?? ''
            copy = replaceValues(copy, values)
        } else {
            notifyError('Copy not found: ' + lang + '-' + key)
        }
        return DOMPurify.sanitize(copy)
    }

    return { translate }
}

import { Button, CrossIcon } from '@itsrever/design-system'
import { ReturnsProductDetailsResponseVariantInfo } from '@itsrever/rever-api'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTheme } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'
import { formatProductPrice } from '@/components/FullCatalog/utils'

interface DesktopVariantSelectorProps {
    name: string
    description?: string
    imageUrl?: string
    variants: Array<ReturnsProductDetailsResponseVariantInfo>
    closeDrawer: () => void
    onVariantSelected: (variant: number | undefined) => void
    selectedVariantIndex: number | undefined
    onConfirmVariantSelection: () => void
    loadingDetails: boolean
    photoList: Array<string>
}

export const DesktopVariantSelector: React.FC<DesktopVariantSelectorProps> = ({
    name,
    description,
    imageUrl,
    variants,
    closeDrawer,
    onVariantSelected,
    selectedVariantIndex,
    onConfirmVariantSelection,
    loadingDetails,
    photoList
}) => {
    const theme = useTheme()
    const { t, i18n } = useTranslation()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'

    const [mainImageUrl, setMainImageUrl] = useState<string>(imageUrl ?? '')
    useEffect(() => {
        setMainImageUrl(imageUrl ?? '')
    }, [imageUrl])

    const changeMainImage = (newImage: string) => {
        if (newImage) {
            setMainImageUrl(newImage)
        } else {
            setMainImageUrl(imageUrl ?? '')
        }
    }

    const onVariantClick = (
        variant: ReturnsProductDetailsResponseVariantInfo,
        index: number
    ) => {
        if (!hasStock(variant)) return
        changeMainImage((variant.image_urls && variant.image_urls[0]) ?? '')
        if (selectedVariantIndex === index) {
            onVariantSelected(undefined)
        } else {
            onVariantSelected(index)
        }
    }

    const hasStock = (variant: ReturnsProductDetailsResponseVariantInfo) => {
        return (
            !variant.is_inventory_enabled ||
            (variant.inventory_quantity !== undefined &&
                variant.inventory_quantity >= 1)
        )
    }

    const sortByStock = (
        a: ReturnsProductDetailsResponseVariantInfo,
        b: ReturnsProductDetailsResponseVariantInfo
    ) => {
        const aHasStock = hasStock(a)
        const bHasStock = hasStock(b)
        if (aHasStock && !bHasStock) {
            return -1
        } else if (!aHasStock && bHasStock) {
            return 1
        } else {
            return 0
        }
    }

    return (
        <div className="mx-auto flex max-h-[550px] w-full max-w-screen-xl justify-center gap-8 rounded-lg bg-white p-12">
            {loadingDetails ? null : (
                <>
                    <button
                        onClick={closeDrawer}
                        className="absolute right-3 top-3 flex size-8 shrink-0 cursor-pointer items-center justify-center rounded bg-gray-100 text-xl text-gray-600 hover:bg-gray-200"
                    >
                        <CrossIcon />
                    </button>
                    <div className="flex gap-2">
                        <div className="flex w-24 flex-col gap-2 overflow-y-scroll">
                            {photoList?.map((photo, i) => {
                                return (
                                    <div
                                        className="relative w-full overflow-hidden rounded-lg bg-cover bg-center bg-no-repeat pb-[100%]"
                                        key={i}
                                        style={{
                                            backgroundImage: `url(${photo})`
                                        }}
                                        onMouseEnter={() =>
                                            changeMainImage(photo)
                                        }
                                    />
                                )
                            })}
                        </div>

                        <img
                            src={mainImageUrl}
                            className="aspect-[2/3] max-h-[30rem] w-full min-w-20 shrink rounded-lg object-cover"
                        />
                    </div>
                    <div className="relative flex min-w-60 flex-1 flex-col">
                        <h5>{name}</h5>
                        <p>{description}</p>
                        <hr className="my-2" />
                        <p className="mb-4 mt-2">
                            {t('full_catalog.select_option')}
                        </p>
                        <div className="flex grow flex-col overflow-scroll">
                            {variants?.sort(sortByStock).map((variant, i) => {
                                return (
                                    <VariantDiv
                                        key={i}
                                        onClick={() => {
                                            onVariantClick(variant, i)
                                        }}
                                        $selected={selectedVariantIndex === i}
                                        $disabled={!hasStock(variant)}
                                    >
                                        <div className="flex w-full items-center justify-between">
                                            <p>
                                                {variant.name ===
                                                'Default Title'
                                                    ? name
                                                    : variant.name}
                                            </p>
                                            {!hasStock(variant) ? (
                                                <p className="ml-4 text-xs text-slate-600">
                                                    {t('full_catalog.no_stock')}
                                                </p>
                                            ) : (
                                                <p className="ml-2 rounded bg-gray-100 px-2 text-sm text-gray-500">
                                                    {formatProductPrice(
                                                        variant.price?.amount,
                                                        variant.price
                                                            ?.currency_code,
                                                        lang
                                                    )}
                                                </p>
                                            )}
                                        </div>
                                    </VariantDiv>
                                )
                            })}
                        </div>
                        <div className="mt-3 flex w-full justify-center">
                            <Button
                                disabled={selectedVariantIndex === undefined}
                                onClick={onConfirmVariantSelection}
                            >
                                {t('full_catalog.confirm_variant')}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

interface VariantDivProps {
    $selected: boolean
    $disabled: boolean
}

const VariantDiv = styled.div<VariantDivProps>`
    margin-bottom: 0.5rem;
    display: flex;
    width: 100%;
    cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'pointer')};
    border-radius: 0.375rem;
    border: ${(p) =>
        p.$selected ? `1px solid var(--brand-600)` : '1px solid #d1d5db'};
    padding: 0.8rem 1rem 0.8rem 1rem;
    background-color: ${(p) => (p.$disabled ? '#d1d5db' : '')};
    &:hover {
        background-color: ${(p) =>
            p.$selected ? 'rgba(255,255,255,0)' : '#e9edf3'};
    }
`

import styled from 'styled-components'
import { useState } from 'react'
import {
    Search,
    ProductCard,
    MobileVariantSelector
} from '@/components/FullCatalog'
import { ShowMoreButton } from '@/components/FullCatalog'
import { CategorySelector } from '@/components/FullCatalog'
import { SwipeableDrawer } from '@mui/material'
import {
    ReturnsProductDetailsResponse,
    PbshoppingProductInfo,
    ReturnsProductDetailsResponseVariantInfo
} from '@itsrever/rever-api'
import { Button, useTheme } from '@itsrever/design-system'
import { ChevronLeftIcon } from '@itsrever/design-system'
import { useTranslation } from 'react-i18next'
import { use100vh } from 'react-div-100vh'
import { useStoreProducts, useStoreMenu, useStoreProductDetails } from '@/hooks'
import {
    formatProductPrice,
    variantsHaveSamePrices
} from '@/components/FullCatalog/utils'

interface MobileFullCatalogProps {
    handleClose: () => void
    handleContinue: (
        signedProductRaw: string,
        variantId: string,
        productName: string,
        variantName: string
    ) => void
}

export const MobileFullCatalog: React.FC<MobileFullCatalogProps> = ({
    handleClose,
    handleContinue
}) => {
    const theme = useTheme()
    const isTransparentMode = theme.colors.primary.main === 'transparent'
    const { t, i18n } = useTranslation()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'

    const height = use100vh()

    const [selectedItem, setSelectedItem] = useState<PbshoppingProductInfo>()
    const [selectedItemDetails, setSelectedItemDetails] =
        useState<ReturnsProductDetailsResponse>()

    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)
    const [search, setSearch] = useState('')

    const { menu, isMenuLoaded } = useStoreMenu()
    const { loadNextPage, isLoadingProducts, products, pageInfo } =
        useStoreProducts(menu, selectedCategoryIndex, search, isMenuLoaded)
    const { fetchProductDetails } = useStoreProductDetails()

    const handleChangeCategory = (idx: number) => {
        setSelectedItem(undefined)
        setSelectedCategoryIndex(idx)
    }
    const [showVariantSelector, setShowVariantSelector] = useState(false)
    const [selectedVariantIndex, setSelectedVariantIndex] = useState<
        number | undefined
    >()
    const [loadingDetails, setLoadingDetails] = useState(false)
    const onProductClick = (product: PbshoppingProductInfo) => {
        setShowVariantSelector(true)
        if (product !== selectedItem) {
            setSelectedVariantIndex(undefined)
        }
        setSelectedItem(product)
        setLoadingDetails(true)
        fetchProductDetails(product.id ?? '')
            .then((res) => {
                if (res) {
                    setSelectedItemDetails(res)
                }
            })
            .catch(() => {
                return
            })
            .finally(() => {
                setLoadingDetails(false)
            })
    }

    const onClickShowMore = () => {
        loadNextPage()
    }

    const onVariantSelected = (variant: number | undefined) => {
        setSelectedVariantIndex(variant)
    }
    const onConfirmVariantSelection = () => {
        handleContinue(
            selectedItemDetails?.signed_product_raw ?? '',
            (selectedItemDetails?.product_info?.variants &&
                selectedItemDetails?.product_info?.variants[
                    selectedVariantIndex ?? 0
                ].platform_id) ??
                '',
            selectedItemDetails?.product_info?.name ?? '',
            (selectedItemDetails?.product_info?.variants &&
                selectedItemDetails?.product_info?.variants[
                    selectedVariantIndex ?? 0
                ].name) ??
                ''
        )
    }
    const onCloseVariantSelector = () => {
        setShowVariantSelector(false)
    }
    const onClickBackButton = () => {
        if (search !== '') {
            setSearch('')
        } else {
            handleClose()
        }
    }

    const hasStock = (variant: ReturnsProductDetailsResponseVariantInfo) => {
        return (
            !variant.is_inventory_enabled ||
            (variant.inventory_quantity !== undefined &&
                variant.inventory_quantity >= 1)
        )
    }
    const productPhotoList = () => {
        const productImages =
            selectedItemDetails?.product_info?.image_urls ?? []
        const variantsImages =
            selectedItemDetails?.product_info?.variants
                ?.filter((variant) => hasStock(variant))
                ?.map((variant) => variant?.image_urls?.[0] ?? '') ?? []
        return Array.from(new Set([...productImages, ...variantsImages]))
    }

    return (
        <Container
            className="relative flex flex-col px-3"
            style={{ height: (height ?? 500) * 0.9 + 'px' }}
        >
            <div className="my-3 flex shrink gap-1">
                <button
                    onClick={onClickBackButton}
                    className="flex shrink-0 cursor-pointer items-center pr-3"
                >
                    <ChevronLeftIcon
                        style={{ transform: 'scale(1.1)' }}
                        color={theme.colors.grey[2]}
                    />
                </button>
                <div className="flex-1">
                    <Search
                        placeholder={t('full_catalog.search') + '...'}
                        value={search}
                        onChange={setSearch}
                    />
                </div>
            </div>
            <div className="my-2">
                <CategorySelector
                    categories={menu}
                    selected={selectedCategoryIndex}
                    handleChange={handleChangeCategory}
                />
            </div>

            <ProductsContainer
                $show={isLoadingProducts === false}
                className="flex flex-col"
            >
                <ItemsDiv>
                    {products?.map((product, i) => {
                        return (
                            <ProductCard
                                key={i}
                                name={product.title ?? ''}
                                imageUrl={product.featured_image_url}
                                description={product.description ?? ''}
                                selected={
                                    selectedItem?.id === product.id &&
                                    selectedVariantIndex !== undefined
                                }
                                price={formatProductPrice(
                                    product.price_range?.min?.amount,
                                    product.price_range?.min?.currency,
                                    lang
                                )}
                                onProductClick={() => onProductClick(product)}
                                isFrom={
                                    !variantsHaveSamePrices(
                                        product?.price_range
                                    )
                                }
                                selectedVariant={
                                    selectedItemDetails?.product_info
                                        ?.variants &&
                                    selectedItemDetails?.product_info?.variants[
                                        selectedVariantIndex ?? 0
                                    ].name
                                }
                            />
                        )
                    })}
                </ItemsDiv>
                {pageInfo?.has_next_page && isLoadingProducts === true && (
                    <div className="my-10 flex">
                        <ShowMoreButton onClick={() => onClickShowMore()} />
                    </div>
                )}

                {search !== '' && products?.length === 0 && (
                    <div className="flex flex-col">
                        <p className="mx-auto mt-4 p-4 text-xl text-gray-400">
                            {t('full_catalog.no_products_found')}
                        </p>
                    </div>
                )}
                {!pageInfo?.has_next_page &&
                    products &&
                    products?.length > 0 && (
                        <p className="mx-auto mb-10 mt-5 border-b-2 border-solid border-gray-400 pb-5 text-sm text-gray-400">
                            {t('full_catalog.showing_of', {
                                number: products.length
                            })}
                        </p>
                    )}

                {selectedItem && selectedVariantIndex !== undefined ? (
                    <div
                        className={
                            'absolute bottom-10 left-1/2 -translate-x-1/2' +
                            (isTransparentMode
                                ? ' rounded-full bg-white shadow-lg'
                                : '')
                        }
                    >
                        <Button onClick={onConfirmVariantSelection}>
                            <span className="mx-6 flex items-center justify-center gap-3 text-base">
                                {t('full_catalog.confirm')}
                                <span className="flex size-5 items-center justify-center rounded-full border border-solid border-white text-center text-xs font-bold">
                                    1
                                </span>
                            </span>
                        </Button>
                    </div>
                ) : null}
            </ProductsContainer>

            <SwipeableDrawer
                anchor={'right'}
                open={showVariantSelector}
                onClose={() => setShowVariantSelector(false)}
                onOpen={() => setShowVariantSelector(true)}
                hideBackdrop={true}
                sx={{
                    top: (height ?? 100) * 0.1 + 'px',
                    height: (height ?? 100) * 0.9 + 'px'
                }}
                PaperProps={{
                    style: {
                        top: (height ?? 100) * 0.1 + 'px',
                        height: (height ?? 100) * 0.9 + 'px',
                        width: '100%'
                    }
                }}
            >
                <div className="relative h-full overflow-y-hidden">
                    <MobileVariantSelector
                        name={selectedItem?.title ?? ''}
                        description={selectedItem?.description ?? ''}
                        imageUrl={selectedItem?.featured_image_url ?? ''}
                        onCloseVariantSelector={onCloseVariantSelector}
                        variants={
                            selectedItemDetails?.product_info?.variants ?? []
                        }
                        selectedVariantIndex={selectedVariantIndex}
                        onVariantSelected={onVariantSelected}
                        loadingDetails={loadingDetails}
                        onConfirmVariantSelection={onConfirmVariantSelection}
                        productPhotoList={productPhotoList()}
                    />
                </div>
            </SwipeableDrawer>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
`

const ItemsDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1rem;
`

interface ProductsContainerProps {
    $show: boolean
}
const ProductsContainer = styled.div<ProductsContainerProps>`
    overflow-y: scroll;
    display: ${(p) => (p.$show ? 'flex' : 'none')};
`

import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { getCopies } from '@/redux/api/settingsApiSlice'
import { useSlug } from '@/hooks'

export function useCopies() {
    const dispatch = useAppDispatch()
    const slug = useSlug()

    const copiesCall = useAppSelector((store) => store.settingsApi.copies)
    const loadingCopies = copiesCall.loading
    const errorCopies = copiesCall.error

    useEffect(() => {
        if (!slug) return
        const promise = dispatch(getCopies(slug))
        return () => promise.abort()
    }, [slug, dispatch])

    return { loadingCopies, errorCopies }
}

import styled from 'styled-components'
import { device } from '@/utils'
import { useTheme } from '@itsrever/design-system'
import PlaceHolder from '@/assets/images/product_placeholder.png'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface ProductCardProps {
    name: string
    description?: string
    isFrom?: boolean
    price?: string
    selected?: boolean
    imageUrl?: string
    secondaryImageUrl?: string
    onProductClick?: () => void
    selectedVariant?: string
}

export const ProductCard: React.FC<ProductCardProps> = ({
    name,
    description,
    price,
    isFrom = false,
    selected = false,
    imageUrl,
    secondaryImageUrl,
    onProductClick,
    selectedVariant
}) => {
    const theme = useTheme()
    const { t } = useTranslation()
    const [isProductHover, setIsProductHover] = useState(false)
    const defaultImage = imageUrl ? imageUrl : PlaceHolder
    const secondaryImage = secondaryImageUrl
    const image =
        isProductHover && secondaryImage ? secondaryImage : defaultImage

    return (
        <Card
            className="group transition-all ease-in-out md:hover:shadow-lg"
            selected={selected}
            onClick={() => {
                if (onProductClick) onProductClick()
            }}
            $color={
                theme.colors.primary.main === 'transparent'
                    ? '#000'
                    : theme.colors.primary.main
            }
        >
            <div className="overflow-hidden rounded-lg md:rounded-none md:rounded-t-lg md:hover:rounded-t-lg">
                <ProductImage
                    src={image}
                    className="transition-all duration-[450ms] ease-in-out group-hover:scale-105"
                    loading="lazy"
                    onMouseEnter={() => setIsProductHover(true)}
                    onMouseLeave={() => setIsProductHover(false)}
                />
            </div>
            <div className="flex flex-col gap-2 px-4 py-3">
                <div>
                    <p>{name}</p>
                    <p
                        className="text-xs"
                        style={{ color: theme.colors.grey[1] }}
                    >
                        {description}
                    </p>
                    {price ? (
                        <p className="mt-1 flex">
                            {isFrom ? (
                                <span className="mr-2 text-slate-400">
                                    {t('full_catalog.from')}
                                </span>
                            ) : null}
                            {price}
                        </p>
                    ) : null}
                </div>
                {selected && selectedVariant !== 'Default Title' && (
                    <div className="flex self-start rounded border border-solid border-slate-200 px-3 py-1 text-xs text-slate-600">
                        {selectedVariant}
                    </div>
                )}
            </div>
        </Card>
    )
}

export default ProductCard

interface CardProps {
    selected: boolean
    $color: string
}

const Card = styled.div<CardProps>`
    background-color: white;
    border-radius: 0.5rem;
    width: 100%;
    height: auto;
    cursor: pointer;
    &:hover {
    }
    @media ${device.md} {
        box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1),
            0 1px 2px -1px rgb(0 0 0 / 0.1);
    }
    ${(p) =>
        p.selected &&
        `border: 1px solid ${p.$color};box-shadow-color: ${p.$color};`}
`

const ProductImage = styled.img`
    aspect-ratio: 4 / 5;
    height: auto;
    width: 100%;
    object-fit: cover;
`

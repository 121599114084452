import styled from 'styled-components'
import { useTheme, CrossIcon, Button } from '@itsrever/design-system'
import { useState } from 'react'
import {
    DesktopNavBar,
    Search,
    DesktopVariantSelector,
    ProductCard,
    ShowMoreButton
} from '@/components/FullCatalog'
import { Drawer } from '@mui/material'
import {
    ReturnsProductDetailsResponse,
    PbshoppingProductInfo,
    ReturnsProductDetailsResponseVariantInfo
} from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'
import { useStoreProducts, useStoreMenu, useStoreProductDetails } from '@/hooks'
import {
    formatProductPrice,
    variantsHaveSamePrices
} from '@/components/FullCatalog/utils'

interface DesktopFullCatalogProps {
    handleClose: () => void
    handleContinue: (
        signedProductRaw: string,
        variantId: string,
        productName: string,
        variantName: string
    ) => void
}

export const DesktopFullCatalog: React.FC<DesktopFullCatalogProps> = ({
    handleClose,
    handleContinue
}) => {
    const theme = useTheme()
    const { t, i18n } = useTranslation()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'
    const isTransparentMode = theme.colors.primary.main === 'transparent'

    const [selectedItem, setSelectedItem] = useState<PbshoppingProductInfo>()
    const [selectedItemDetails, setSelectedItemDetails] =
        useState<ReturnsProductDetailsResponse>()
    const [selectedVariantIndex, setSelectedVariantIndex] = useState<
        number | undefined
    >()

    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)
    const [search, setSearch] = useState('')
    const [variantDrawerOpen, setVariantDrawerOpen] = useState(false)
    const [loadingDetails, setLoadingDetails] = useState(false)
    const { menu, isMenuLoaded } = useStoreMenu()
    const { loadNextPage, isLoadingProducts, products, pageInfo } =
        useStoreProducts(menu, selectedCategoryIndex, search, isMenuLoaded)
    const { fetchProductDetails } = useStoreProductDetails()

    const handleChangeCategory = (idx: number) => {
        setSelectedItem(undefined)
        setSelectedCategoryIndex(idx)
    }

    const onProductClick = (product: PbshoppingProductInfo) => {
        setVariantDrawerOpen(true)
        if (product !== selectedItem) {
            setSelectedVariantIndex(undefined)
        }
        setSelectedItem(product)
        setLoadingDetails(true)
        fetchProductDetails(product.id ?? '')
            .then((res) => {
                if (res) {
                    setSelectedItemDetails(res)
                }
            })
            .catch(() => {
                return
            })
            .finally(() => {
                setLoadingDetails(false)
            })
    }

    const onClickShowMore = () => {
        loadNextPage()
    }

    const onCloseVariantDrawer = () => {
        setVariantDrawerOpen(false)
    }

    const onVariantSelected = (variant: number | undefined) => {
        setSelectedVariantIndex(variant)
    }

    const onConfirmVariantSelection = () => {
        handleContinue(
            selectedItemDetails?.signed_product_raw ?? '',
            (selectedItemDetails?.product_info?.variants &&
                selectedItemDetails?.product_info?.variants[
                    selectedVariantIndex ?? 0
                ].platform_id) ??
                '',
            selectedItemDetails?.product_info?.name ?? '',
            (selectedItemDetails?.product_info?.variants &&
                selectedItemDetails?.product_info?.variants[
                    selectedVariantIndex ?? 0
                ].name) ??
                ''
        )
    }

    const hasStock = (variant: ReturnsProductDetailsResponseVariantInfo) => {
        return (
            !variant.is_inventory_enabled ||
            (variant.inventory_quantity !== undefined &&
                variant.inventory_quantity >= 1)
        )
    }

    const productPhotoList = () => {
        const productImages =
            selectedItemDetails?.product_info?.image_urls ?? []
        const variantsImages =
            selectedItemDetails?.product_info?.variants
                ?.filter((variant) => hasStock(variant))
                ?.map((variant) => variant?.image_urls?.[0] ?? '') ?? []
        return Array.from(new Set([...productImages, ...variantsImages]))
    }

    return (
        <>
            <Container bgColor={theme.colors.grey[5]}>
                <DesktopNavBar
                    categories={menu}
                    selectedCategory={selectedCategoryIndex}
                    handleChangeCategory={handleChangeCategory}
                />
                <div
                    className="absolute right-2 top-2 z-50 cursor-pointer rounded p-2 hover:bg-gray-200"
                    onClick={handleClose}
                >
                    <CrossIcon />
                </div>
                <div className="relative flex w-full flex-col px-6 pt-6 lg:mx-auto lg:max-w-[1500px]">
                    <Search
                        placeholder={t('full_catalog.search') + '...'}
                        value={search}
                        onChange={setSearch}
                    />
                    <h4 className="mb-4 mt-6 shrink-0 font-medium text-gray-800">
                        {search ? search : menu[selectedCategoryIndex]?.title}
                    </h4>
                    <ProductsContainer $show={isLoadingProducts === false}>
                        <div className="w-full overflow-y-scroll">
                            <ItemsDiv>
                                {products?.map((product) => {
                                    const secondaryImage =
                                        product.image_urls &&
                                        product.image_urls?.length >= 2
                                            ? product.image_urls[1]
                                            : ''
                                    return (
                                        <ProductCard
                                            key={product.id}
                                            name={product.title ?? ''}
                                            imageUrl={
                                                product.featured_image_url ?? ''
                                            }
                                            secondaryImageUrl={secondaryImage}
                                            description={
                                                product.description ?? ''
                                            }
                                            selected={
                                                selectedItem?.id ===
                                                    product.id &&
                                                selectedVariantIndex !==
                                                    undefined
                                            }
                                            isFrom={
                                                !variantsHaveSamePrices(
                                                    product?.price_range
                                                )
                                            }
                                            price={formatProductPrice(
                                                product.price_range?.min
                                                    ?.amount,
                                                product.price_range?.min
                                                    ?.currency,
                                                lang
                                            )}
                                            onProductClick={() =>
                                                onProductClick(product)
                                            }
                                            selectedVariant={
                                                selectedItemDetails
                                                    ?.product_info?.variants &&
                                                selectedItemDetails
                                                    ?.product_info?.variants[
                                                    selectedVariantIndex ?? 0
                                                ].name
                                            }
                                        />
                                    )
                                })}
                            </ItemsDiv>

                            {pageInfo?.has_next_page &&
                                isLoadingProducts === false && (
                                    <div className="mb-7 mt-8 flex">
                                        <ShowMoreButton
                                            onClick={() => onClickShowMore()}
                                        />
                                    </div>
                                )}

                            {search !== '' && products?.length === 0 && (
                                <div className="flex flex-col">
                                    <p className="mx-auto mt-4 text-xl text-gray-400">
                                        {t('full_catalog.no_products_found')}
                                    </p>
                                </div>
                            )}
                            {!pageInfo?.has_next_page &&
                                products &&
                                products?.length > 0 && (
                                    <div className="mb-7 mt-8 flex">
                                        <p className="mx-auto border-b-2 border-solid border-gray-400 pb-5 text-sm text-gray-400">
                                            {t('full_catalog.showing_of', {
                                                number: products?.length
                                            })}
                                        </p>
                                    </div>
                                )}
                        </div>
                        {selectedItem && selectedVariantIndex !== undefined ? (
                            <div
                                className={
                                    'absolute bottom-10 left-1/2 -translate-x-1/2 ' +
                                    (isTransparentMode
                                        ? ' rounded-full bg-white shadow-lg'
                                        : '')
                                }
                            >
                                <Button onClick={onConfirmVariantSelection}>
                                    <span className="mx-6 flex items-center justify-center gap-3 text-base">
                                        {t('full_catalog.confirm')}
                                        <span className="flex size-5 items-center justify-center rounded-full border border-solid border-white text-center text-xs font-bold">
                                            1
                                        </span>
                                    </span>
                                </Button>
                            </div>
                        ) : null}
                    </ProductsContainer>
                </div>
            </Container>
            <Drawer
                anchor="bottom"
                open={variantDrawerOpen}
                onClose={onCloseVariantDrawer}
                sx={{
                    backdropFilter: 'blur(1px)',
                    borderTopLeftRadius: '0.5rem',
                    borderTopRightRadius: '0.5rem'
                }}
                PaperProps={{
                    style: {
                        height: '600px',
                        top: 'calc(100% - 600px)',
                        borderTopLeftRadius: '0.5rem',
                        borderTopRightRadius: '0.5rem'
                    }
                }}
            >
                <DesktopVariantSelector
                    name={selectedItem?.title ?? ''}
                    description={selectedItem?.description ?? ''}
                    imageUrl={selectedItem?.featured_image_url ?? ''}
                    variants={selectedItemDetails?.product_info?.variants ?? []}
                    closeDrawer={() => setVariantDrawerOpen(false)}
                    onVariantSelected={onVariantSelected}
                    selectedVariantIndex={selectedVariantIndex}
                    onConfirmVariantSelection={onConfirmVariantSelection}
                    loadingDetails={loadingDetails}
                    photoList={productPhotoList()}
                />
            </Drawer>
        </>
    )
}

const Container = styled.div<{ bgColor: string }>`
    z-index: 9;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: ${(p) => p.bgColor};
`

const ItemsDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: 1rem;
`
interface ProductsContainerProps {
    $show: boolean
}
const ProductsContainer = styled.div<ProductsContainerProps>`
    overflow-y: scroll;
    display: ${(p) => (p.$show ? 'flex' : 'none')};
    transition: all 0.5s ease-in;
`

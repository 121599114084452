import {
    ReturnsTrackingInfoRequest,
    ReturnsApi,
    ReturnsLabelPresignedURLRequest,
    ReturnsDeliveryNotePresignedURLRequest,
    ReturnsReturnProcessIdentification
} from '@itsrever/rever-api'
import { AxiosError } from 'axios'
import { axiosInstance } from '@/redux/api/apiConfiguration'

const returnsApi = new ReturnsApi(undefined, undefined, axiosInstance)

export async function getLabelURL(
    returnsLabelPresignedURLRequest: ReturnsLabelPresignedURLRequest
) {
    try {
        const response = await returnsApi.labelPresignedURL({
            returnsLabelPresignedURLRequest
        })
        return response.data
    } catch (error) {
        const err = error as AxiosError
        return err.response?.status
    }
}

export async function getDeliveryNoteURL(
    returnsDeliveryNotePresignedURLRequest: ReturnsDeliveryNotePresignedURLRequest
) {
    try {
        const response = await returnsApi.deliveryNotePresignedURL({
            returnsDeliveryNotePresignedURLRequest
        })
        return response.data
    } catch (error) {
        const err = error as AxiosError
        console.error(err)
        return err.response?.status
    }
}

export async function getInstructions(
    return_process_identification: ReturnsReturnProcessIdentification
) {
    try {
        const response = await returnsApi.instructionsPresignedURL({
            returnsInstructionsPresignedURLRequest: {
                return_process_identification
            }
        })
        return response.data
    } catch (error) {
        const err = error as AxiosError
        console.error(err)
        return err.response?.status
    }
}

export async function getTrackingInfo(
    returnsTrackingInfoRequest: ReturnsTrackingInfoRequest
) {
    const response = await returnsApi.trackingInfo({
        returnsTrackingInfoRequest
    })
    return response.data
}

import { ScrollToTop } from '@/components/ScrollToTop'
import { useSettings, useCopies, usePageVisit } from '@/hooks'
import { Toaster, ThemeProvider } from '@itsrever/design-system'
import { UnstyledLoading } from '@/components/Loading'
import { Error, NotFound } from '@/pages'
import { Outlet } from 'react-router-dom'

export function AppLayout() {
    usePageVisit()

    const { settings, loadingSettings, errorSettings, theme } = useSettings()
    const { loadingCopies, errorCopies } = useCopies()

    if (loadingSettings === 'pending' || loadingCopies === 'pending')
        return <UnstyledLoading loading={true} />

    if (errorSettings || !settings.is_enabled) return <NotFound />
    if (errorCopies) return <Error />

    return (
        <ScrollToTop>
            <ThemeProvider theme={theme}>
                <Toaster />
                <Outlet />
            </ThemeProvider>
        </ScrollToTop>
    )
}

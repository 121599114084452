import { getDeliveryNoteURL } from '@/api/trackingApi'
import { captureEventPosthog } from '@/services/Posthog'
import { toast } from '@itsrever/design-system'

export function useDownloadDeliveryNote() {
    async function downloadDeliveryNote(processId: string, email: string) {
        captureEventPosthog('Download Delivery Note', {
            processId
        })
        const return_process_identification = {
            process_id: processId,
            email
        }
        const data = await getDeliveryNoteURL({ return_process_identification })

        if (typeof data !== 'number' && data) {
            if (
                /iphone/i.test(navigator.userAgent) ||
                /ipad/i.test(navigator.userAgent)
            ) {
                window.location.href = data.url ?? ''
            } else {
                window.open(data.url, '_blank')
            }
            return
        }
        if (data === 404) {
            toast({
                text: 'Delivery note not found!',
                variant: 'error'
            })
            return
        }
        toast({
            text: 'Something went wrong! Please try again later',
            variant: 'error'
        })
    }

    return { downloadDeliveryNote }
}

import { useEffect, useState } from 'react'
import { useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { toast } from '@itsrever/design-system'
import { PkgInfrastructureRestProvinceData as RestProvinceData } from '@itsrever/returns-api-types'
import { useNavigate } from 'react-router-dom'
import { usePages } from '@/hooks'
import axios from 'axios'
import { getProvinces } from '@/api/locationsApi'

export function useProvinces(
    countryCode: string | undefined,
    defaultProvinceCode?: string
) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { pages } = usePages()

    const orderInfo = useAppSelector((store) => store.order)
    const orderNumber = orderInfo.orderNumber
    const email = orderInfo.email

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const ecommerceID = settings.ecommerce_id

    const [defaultProvince, setDefaultProvince] = useState<RestProvinceData>()
    const [provinces, setProvinces] = useState<RestProvinceData[]>()
    const [loadingProvinces, setLoading] = useState(false)

    useEffect(() => {
        async function callProvinces() {
            if (!ecommerceID || !countryCode) return
            try {
                setLoading(true)
                const response = await getProvinces(ecommerceID, countryCode)

                if (!response) return
                setProvinces(response)
                const defaultProvince = response.find(
                    (x) => x.code === defaultProvinceCode
                )
                setDefaultProvince(defaultProvince)
            } catch (error) {
                if (axios.isAxiosError(error)) {
                    if (
                        error.response?.status === 401 ||
                        error.response?.status === 419
                    ) {
                        toast({
                            text: t('toast_errors.error_419'),
                            variant: 'error'
                        })
                        navigate(
                            `${pages.Landing}?orderNumber=${encodeURIComponent(
                                orderNumber || ''
                            )}&email=${encodeURIComponent(email || '')}`
                        )
                        return
                    }
                    toast({
                        text: t('toast_errors.provinces_500'),
                        variant: 'error'
                    })
                    navigate(pages.Error)
                }
            } finally {
                setLoading(false)
            }
        }

        callProvinces()
    }, [
        ecommerceID,
        countryCode,
        defaultProvinceCode,
        navigate,
        orderNumber,
        email,
        t,
        pages.Error,
        pages.Landing
    ])

    return {
        provinces,
        defaultProvince,
        loadingProvinces
    }
}

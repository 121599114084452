import { ApiReturnedItem } from '@itsrever/returns-api-types'
import {
    ReturnsLineItem as ModelsLineItem,
    ReturnsExchangeOptionsResponse
} from '@itsrever/rever-api'
import { useExchangeOptions } from '@/hooks'
import { useState } from 'react'
import {
    ReturnsExchangeOptionsExchangeFlowEnum,
    ReturnsExchangeOptionsResponseExchangeOptionsExchangeFlowEnum
} from '@itsrever/rever-api'
import {
    Initial,
    SelectExchangeOpen,
    SelectReason,
    SelectExchangeOneOnOne
} from '@/components/ItemSelectionComponents'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import {
    ApiExchangeSelectionFront,
    ItemActions,
    RefundActions,
    addExchangeSelection,
    addReturnedItem
} from '@/redux/return/returnSlice'
import { useMediaQuery, Drawer } from '@mui/material'
import { Modal } from '@itsrever/design-system'
import { DesktopFullCatalog, MobileFullCatalog } from '@/components/FullCatalog'
import { ExchangeFlows } from '@/utils/types'
import { captureEventPosthog, isPosthogFFEnabled } from '@/services/Posthog'
import { SuggestionsByReason } from './SuggestionsByReason'
import { ProductSuggestions } from './ProductSuggestions'

interface SelectionFlowProps {
    product: ModelsLineItem
    subProductId: number
    isOpen: boolean
    handleClose: () => void
}

export const SelectionFlow: React.FC<SelectionFlowProps> = ({
    product,
    subProductId,
    isOpen,
    handleClose
}) => {
    return (
        <FlowManager
            product={product}
            subProductId={subProductId}
            handleClose={handleClose}
            isOpen={isOpen}
        />
    )
}

type FlowSteps =
    | 'INITIAL'
    | 'SELECT_REASON'
    | 'SELECT_EXCHANGE_ONE_ON_ONE'
    | 'SELECT_EXCHANGE_OPEN'
    | 'FULL_CATALOG'
    | 'SUGGESTION_BY_REASON'
    | 'PRODUCT_SUGGESTIONS'

interface FlowManagerProps {
    product: ModelsLineItem
    subProductId: number
    handleClose: () => void
    isOpen: boolean
}

const FlowManager: React.FC<FlowManagerProps> = ({
    product,
    subProductId,
    handleClose,
    isOpen
}) => {
    const dispatch = useAppDispatch()
    const isMobile = useMediaQuery('(max-width:599px)')
    const group = useAppSelector(
        (store) => store.settingsApi.settings.response.group
    )
    const isTesting = useAppSelector(
        (store) => store.settingsApi.settings.response.is_testing_account
    )
    const [selectedQuantity, setSelectedQuantity] = useState(1)
    const [selectedReason, setSelectedReason] = useState('')
    const { loadingExchangeOptions, callExchangeOptions } = useExchangeOptions(
        product.id,
        selectedReason,
        selectedQuantity
    )
    const [exchangeOptions, setExchangeOptions] =
        useState<ReturnsExchangeOptionsResponse>({})

    const exchangeFlow = exchangeOptions?.exchange_options?.exchange_flow
    const initialStep: FlowSteps = 'SELECT_REASON'
    const isFullCatalog =
        exchangeFlow === ReturnsExchangeOptionsExchangeFlowEnum.Full
    const variants = exchangeOptions?.line_item_product_info?.variants ?? []
    const suggestionsByReasonEnabled =
        isPosthogFFEnabled('suggestions-by-reason') ?? false

    const [selectedExchangeType, setSelectedExchangeType] =
        useState<ReturnsExchangeOptionsExchangeFlowEnum>()
    const [flowStep, setFlowStep] = useState<FlowSteps>(initialStep)
    const [selectedVariantId, setSelectedVariantId] = useState('')

    const handleContinueReason = async () => {
        callExchangeOptions().then((response) => {
            if (response) {
                setExchangeOptions(response ?? {})
                const flow = response?.exchange_options?.exchange_flow
                const isExchange = isExchangeAvailable(flow)
                if (!isExchange) {
                    continueWithRefund()
                    return
                }
                setFlowStep('INITIAL')
            }
        })
    }

    const handleMethodSelected = (
        exchangeMethod: ReturnsExchangeOptionsExchangeFlowEnum | undefined,
        isRefund?: boolean
    ) => {
        const vetoReasons = ['DEFECTIVE', 'WRONG_ITEM']

        if (group === 'twojeys') {
            if (vetoReasons.includes(selectedReason)) {
                continueWithRefund()
                return
            }
        }

        const specialReasons = [
            'WRONG_SIZE_TOO_SMALL',
            'WRONG_SIZE_TOO_BIG',
            'WRONG_COLOR',
            'DEFECTIVE',
            'WRONG_ITEM'
        ]
        const specialReasonSelected = specialReasons.includes(selectedReason)
        const productSuggestionsEnabled = !suggestionsByReasonEnabled
            ? Math.random() >= 0.5
            : false

        if (specialReasonSelected) {
            captureEventPosthog('Special exchange reason selected')
        }
        if (isRefund) {
            if (specialReasonSelected && suggestionsByReasonEnabled) {
                setFlowStep('SUGGESTION_BY_REASON')
                return
            } else if (
                productSuggestionsEnabled &&
                exchangeFlow === 'EXCHANGE_FLOW_FULL'
            ) {
                captureEventPosthog('FCS true')
                setFlowStep('PRODUCT_SUGGESTIONS')
                return
            } else {
                if (!productSuggestionsEnabled) {
                    captureEventPosthog('FCS false')
                }
            }
        }
        if (exchangeMethod) {
            switch (exchangeMethod) {
                case ReturnsExchangeOptionsExchangeFlowEnum.Open ||
                    ReturnsExchangeOptionsExchangeFlowEnum.OpenWithGiftCard:
                    setFlowStep('SELECT_EXCHANGE_OPEN')
                    break
                case ReturnsExchangeOptionsExchangeFlowEnum.OneOnOne:
                    setFlowStep('SELECT_EXCHANGE_ONE_ON_ONE')
                    break
                case ReturnsExchangeOptionsExchangeFlowEnum.Full:
                    setFlowStep('FULL_CATALOG')
                    break
                default:
                    break
            }
        } else {
            continueWithRefund()
        }
    }

    const handleContinueExchange = () => {
        pushExchangedItem()
        handleClose()
    }

    const handleContinueExchangeFullCatalog = (
        signedProductRaw: string,
        variantId: string,
        productName: string,
        variantName: string
    ) => {
        pushExchangedItemFullCatalog(
            signedProductRaw,
            variantId,
            productName,
            variantName
        )
        handleClose()
    }

    const handleClickFullCatalog = () => {
        if (!isTesting)
            captureEventPosthog('Exchange FullCatalog Clicked', {
                from: 'Initial'
            })
        setSelectedExchangeType('EXCHANGE_FLOW_FULL')
        handleMethodSelected('EXCHANGE_FLOW_FULL')
    }

    const handleClickExchange = () => {
        if (!isTesting)
            captureEventPosthog('Exchange OneOnOne Clicked', {
                exchangeFlow: toExchangeOptionExchangeFlow(exchangeFlow)
            })
        setSelectedExchangeType('EXCHANGE_FLOW_ONE_ON_ONE')
        handleMethodSelected('EXCHANGE_FLOW_ONE_ON_ONE')
    }

    const handleClickExchangeFromReason = () => {
        if (!isTesting)
            captureEventPosthog('Exchange OneOnOne Clicked from reason', {
                exchangeFlow: toExchangeOptionExchangeFlow(exchangeFlow)
            })
        setSelectedExchangeType('EXCHANGE_FLOW_ONE_ON_ONE')
        setFlowStep('SELECT_EXCHANGE_ONE_ON_ONE')
    }

    const handleClickFullCatalogFromReason = () => {
        setSelectedExchangeType('EXCHANGE_FLOW_FULL')
        setFlowStep('FULL_CATALOG')
    }

    const continueWithRefund = () => {
        pushReturnedItem(RefundActions.ToReturn, ItemActions.ToReturn)
        handleClose()
    }

    const handleClickRefund = () => {
        handleMethodSelected(undefined, true)
    }

    function pushReturnedItem(
        action: RefundActions,
        itemActions: (typeof ItemActions)[keyof typeof ItemActions]
    ) {
        const item: ApiReturnedItem = {
            line_item_id: product.id ?? '',
            quantity: selectedQuantity,
            product_return_reason: selectedReason,
            action
        }
        dispatch(
            addReturnedItem({
                item,
                lineItemId: product.id ?? '',
                subProductId,
                action: itemActions
            })
        )
    }

    function pushExchangedItem() {
        const selection: ApiExchangeSelectionFront = {
            exchangeSelection: {
                line_item_platform_id: product.id ?? '',
                quantity: selectedQuantity,
                signed_exchange_options_raw:
                    exchangeOptions?.signed_exchange_options_raw,
                signed_product_raw:
                    exchangeOptions?.signed_line_item_product_raw,
                variant_id: selectedVariantId
            },
            originalItemSubproductId: subProductId,
            productName: product.name ?? '',
            variantName:
                variants.find(
                    (variant) => variant.platform_id === selectedVariantId
                )?.name ?? ''
        }
        pushReturnedItem(
            RefundActions.ToExchange,
            ItemActions.ToExchangeOneOnOne
        )
        dispatch(addExchangeSelection(selection))
    }

    function pushExchangedItemFullCatalog(
        signedProductRaw: string,
        variantId: string,
        productName: string,
        variantName: string
    ) {
        const selection: ApiExchangeSelectionFront = {
            exchangeSelection: {
                line_item_platform_id: product.id ?? '',
                quantity: 1,
                signed_exchange_options_raw:
                    exchangeOptions?.signed_exchange_options_raw,
                signed_product_raw: signedProductRaw,
                variant_id: variantId
            },
            originalItemSubproductId: subProductId,
            productName,
            variantName
        }
        pushReturnedItem(
            RefundActions.ToExchange,
            ItemActions.ToExchangeFullCatalog
        )
        dispatch(addExchangeSelection(selection))
    }

    function renderFlowStep(flowStep: FlowSteps | undefined) {
        switch (flowStep) {
            case 'INITIAL':
                return renderContainer(
                    <Initial
                        product={product}
                        handleClickExchange={handleClickExchange}
                        handleClickRefund={handleClickRefund}
                        handleClickFullCatalog={handleClickFullCatalog}
                        exchangeOptions={exchangeOptions ?? {}}
                        loading={loadingExchangeOptions}
                    />
                )
            case 'SELECT_REASON':
                return renderContainer(
                    <SelectReason
                        product={product}
                        selectedQuantity={selectedQuantity}
                        setSelectedQuantity={setSelectedQuantity}
                        selectedReason={selectedReason}
                        setSelectedReason={setSelectedReason}
                        handleContinue={handleContinueReason}
                        isFullCatalog={
                            selectedExchangeType === 'EXCHANGE_FLOW_FULL'
                        }
                    />
                )

            case 'SELECT_EXCHANGE_ONE_ON_ONE':
                return renderContainer(
                    <SelectExchangeOneOnOne
                        product={product}
                        variants={variants}
                        selectedVariantId={selectedVariantId}
                        setSelectedVariantId={setSelectedVariantId}
                        handleContinue={handleContinueExchange}
                        handleClickFullCatalog={() => {
                            if (!isTesting)
                                captureEventPosthog(
                                    'Exchange FullCatalog Clicked',
                                    {
                                        from: 'OneOnOne'
                                    }
                                )
                            setFlowStep('FULL_CATALOG')
                        }}
                        isFullCatalog={isFullCatalog}
                        selectedQuantity={selectedQuantity}
                    />
                )
            case 'SELECT_EXCHANGE_OPEN':
                return renderContainer(
                    <SelectExchangeOpen
                        product={product}
                        handleContinue={handleContinueExchange}
                    />
                )
            case 'FULL_CATALOG':
                return isMobile ? (
                    renderContainer(
                        <MobileFullCatalog
                            handleClose={handleClose}
                            handleContinue={handleContinueExchangeFullCatalog}
                        />
                    )
                ) : (
                    <DesktopFullCatalog
                        handleClose={handleClose}
                        handleContinue={handleContinueExchangeFullCatalog}
                    />
                )
            case 'PRODUCT_SUGGESTIONS':
                return renderContainer(
                    <ProductSuggestions
                        handleClickFullCatalog={
                            handleClickFullCatalogFromReason
                        }
                        handleContinue={continueWithRefund}
                    />
                )
            case 'SUGGESTION_BY_REASON':
                return renderContainer(
                    <SuggestionsByReason
                        selectedReason={selectedReason}
                        handleContinue={continueWithRefund}
                        handleClickExchange={handleClickExchangeFromReason}
                    />
                )
            default:
                return null
        }
    }

    const renderContainer = (children: React.ReactNode) => {
        if (isMobile) {
            return (
                <Drawer
                    anchor="bottom"
                    open={isOpen}
                    onClose={handleClose}
                    sx={{
                        backdropFilter: 'blur(5px)'
                    }}
                >
                    {children}
                </Drawer>
            )
        }
        return (
            <Modal isOpen={isOpen} onRequestClose={handleClose} closeButton>
                {children}
            </Modal>
        )
    }

    return <>{renderFlowStep(flowStep)}</>
}

function isExchangeAvailable(
    exchangeFlow: ReturnsExchangeOptionsExchangeFlowEnum | undefined
) {
    switch (exchangeFlow) {
        case ReturnsExchangeOptionsExchangeFlowEnum.OneOnOne:
            return true
        case ReturnsExchangeOptionsExchangeFlowEnum.Open:
            return true
        case ReturnsExchangeOptionsExchangeFlowEnum.OpenWithGiftCard:
            return true
        case ReturnsExchangeOptionsExchangeFlowEnum.Full:
            return true
        default:
            return false
    }
}

function toExchangeOptionExchangeFlow(
    exchangeFlow:
        | ReturnsExchangeOptionsResponseExchangeOptionsExchangeFlowEnum
        | undefined
): (typeof ExchangeFlows)[keyof typeof ExchangeFlows] {
    switch (exchangeFlow) {
        case ReturnsExchangeOptionsResponseExchangeOptionsExchangeFlowEnum.Open:
            return ExchangeFlows.Open
        case ReturnsExchangeOptionsResponseExchangeOptionsExchangeFlowEnum.OpenWithGiftCard:
            return ExchangeFlows.OpenWithGiftCard
        case ReturnsExchangeOptionsResponseExchangeOptionsExchangeFlowEnum.OneOnOne:
            return ExchangeFlows.OneOnOne
        case ReturnsExchangeOptionsResponseExchangeOptionsExchangeFlowEnum.Full:
            return ExchangeFlows.Full
        default:
            return ExchangeFlows.None
    }
}

import * as Sentry from '@sentry/react'

const sentryDsn = import.meta.env.VITE_PUBLIC_SENTRY_DSN

export function initializeSentry() {
    Sentry.init({
        dsn: sentryDsn,
        integrations: [new Sentry.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.2,
        ignoreErrors: [
            "Can't find variable: showKeyboard",
            "Can't find variable: hideKeyboard",
            "Can't find variable: msDiscoverChatAvailable",
            'Cannot redefine property: BetterJsPop',
            /_avast_submit/i
        ]
    })
}

export function notifyError(error: unknown) {
    Sentry.captureException(error)
}

export function setUserSentry(email: string | undefined) {
    if (!email) return
    Sentry.setUser({
        email
    })
}

export function setTagsSentry(tags: { [key: string]: string }) {
    Sentry.setTags({
        ...tags
    })
}

import { useTheme } from '@itsrever/design-system'
import { useRef, useState } from 'react'
import styled from 'styled-components'

export const Search = ({ ...props }) => {
    const theme = useTheme()
    const [isFocused, setIsFocused] = useState(false)
    const inputRef = useRef(null)
    const onClearInput = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.preventDefault()
        props.onChange('')
    }
    const onBlurInput = (e: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(false)
    }
    return (
        <div
            className={
                'relative bg-white h-10 rounded-lg border outline outline-2 border-solid border-gray-200 flex w-full shrink-0 flex-col transition-[width] duration-300 ease-in-out md:w-[15em] ' +
                (isFocused ? 'md:w-[25em]' : '')
            }
            style={{
                outlineColor: isFocused
                    ? theme.colors.primary.main === 'transparent'
                        ? '#000'
                        : theme.colors.primary.main
                    : 'transparent'
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="absolute inset-y-0 left-3 top-2 h-5 w-5 text-gray-400"
            >
                <path
                    fillRule="evenodd"
                    d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                    clipRule="evenodd"
                />
            </svg>

            <SearchInput
                ref={inputRef}
                {...props}
                onChange={(e) => props.onChange(e.target.value)}
                className="absolute inset-x-10 inset-y-0 appearance-none text-sm text-gray-500"
                onFocus={() => setIsFocused(true)}
                onBlur={(e) => onBlurInput(e)}
            />
            {props.value && (
                <div
                    id="clear-input"
                    onMouseDown={(e) => onClearInput(e)}
                    className="absolute inset-y-0 right-1 z-10 my-auto h-7 w-7 cursor-pointer rounded-md p-0 text-gray-400 hover:bg-gray-200"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                    >
                        <path d="M12.72 13.78a.75.75 0 1 0 1.06-1.06l-2.72-2.72 2.72-2.72a.75.75 0 0 0-1.06-1.06l-2.72 2.72-2.72-2.72a.75.75 0 0 0-1.06 1.06l2.72 2.72-2.72 2.72a.75.75 0 1 0 1.06 1.06l2.72-2.72 2.72 2.72Z" />
                    </svg>
                </div>
            )}
        </div>
    )
}

const SearchInput = styled.input`
    border-width: 0px;
    outline: none;
    box-shadow: none;
    outline-offset: 0px;
    &:focus {
        outline: none;
        box-shadow: none;
        outline-offset: 0px;
    }
`

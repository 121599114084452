import styled from 'styled-components'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTheme } from '@itsrever/design-system'
import {
    RefundPaymentMethod,
    setRefundPaymentMethod,
    setKeepYourItem
} from '@/redux/return/returnSlice'
import { RefundTimings } from '@/redux/return/returnSlice'
import { ReactComponent as GiftCard } from '@/assets/images/icons/gift-card.svg'
import BankTransfer from '@/assets/images/icons/bank-transfer.svg'
import OPM from '@/assets/images/icons/opm.svg'
import { useTranslate } from '@/hooks'
import { toRGB } from '@/utils'
import {
    GithubComItsreverReturnsApiPkgDomainRefundmethodsKeepYourItem as RestKeepYourItem,
    GithubComItsreverReturnsApiPkgDomainRefundmethodsRefundMethod as RestRefundMethod,
    GithubComItsreverReturnsApiPkgDomainRefundmethodsRDVSurplus as rdvSurplus
} from '@itsrever/returns-api-types'
import { useTranslation } from 'react-i18next'

interface RefundCardProps {
    method: RestRefundMethod
    keepYourItem?: RestKeepYourItem
    totalRefundPrice?: number
    displayShippingTag?: boolean
    keepYourItemOffered?: boolean
    selected?: boolean
    rdvSurplus?: rdvSurplus
    isRDVSurplusOffered?: boolean
}

export const RefundCard: React.FC<RefundCardProps> = ({
    method,
    totalRefundPrice,
    displayShippingTag,
    keepYourItemOffered,
    selected = false,
    rdvSurplus,
    isRDVSurplusOffered = false
}) => {
    const { translate } = useTranslate()
    const { i18n } = useTranslation()

    const dispatch = useAppDispatch()
    const theme = useTheme()

    const instantMethod =
        method.method === RefundPaymentMethod.GiftCard ||
        method.method === RefundPaymentMethod.PromoCode

    const tagBackgroundColor = instantMethod
        ? '#1b75eb'
        : selected
        ? theme.colors.primary.main
        : theme.colors.grey[0]
    const tagColor = theme.colors.common.white

    const iconSource =
        method.method === RefundPaymentMethod.BankTransfer ? BankTransfer : OPM

    const isRDVSurplus =
        (rdvSurplus?.surplus_amount ?? 0) > 0 ||
        (rdvSurplus?.surplus_percentage ?? 0) > 0

    const currency =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        )?.currency ?? 'unknown'

    const formatProductPrice = (
        amount: string | undefined,
        currency: string | undefined
    ) => {
        const lang = i18n.language.toLocaleUpperCase() ?? 'EN'
        const currencyCode = currency ?? 'EUR'
        const amountValue = amount ?? '0'
        return new Intl.NumberFormat(lang, {
            style: 'currency',
            currency: currencyCode
        }).format(parseFloat(amountValue))
    }

    const getRDVAmount = (amount: number) => {
        const amountDivided = (amount ?? 0) / 100
        return formatProductPrice(amountDivided.toString(), currency)
    }

    const totalAmount = (totalRefundPrice ?? 0) / 100

    const returnTotalAmount = formatProductPrice(
        totalAmount.toString(),
        currency
    )
    const totalRDVSurplus = () => {
        if (rdvSurplus?.surplus_amount) {
            const amount = totalAmount + rdvSurplus?.surplus_amount / 100
            return formatProductPrice(amount.toString(), currency)
        } else if (rdvSurplus?.surplus_percentage) {
            const amount =
                totalAmount +
                totalAmount * (rdvSurplus?.surplus_percentage / 100)
            return formatProductPrice(amount.toString(), currency)
        } else {
            return formatProductPrice('0', currency)
        }
    }

    return (
        <div
            id={method.method?.toString()}
            className="relative flex content-center items-center"
            onClick={() => {
                dispatch(setRefundPaymentMethod(method ?? null))
                dispatch(setKeepYourItem(false))
            }}
        >
            {instantMethod && displayShippingTag ? (
                <FreeShippingLabel>
                    <p className="text-xs">
                        <b
                            dangerouslySetInnerHTML={{
                                __html: translate('refund_card_free_shipping')
                            }}
                        />
                    </p>
                </FreeShippingLabel>
            ) : null}
            <Card
                selected={selected}
                isBlueOption={(instantMethod && displayShippingTag) ?? false}
                borderColor={theme.colors.primary.main}
                bgColor={toRGB(theme.colors.primary.main)}
            >
                <div className="flex items-center gap-2">
                    {instantMethod ? (
                        <GiftCardIcon selected={selected} />
                    ) : (
                        <Icon src={iconSource} selected={selected} />
                    )}
                    <p className="flex items-center truncate text-lg">
                        <b
                            dangerouslySetInnerHTML={{
                                __html: translate(
                                    `refund_card_method${method.method}_title`
                                )
                            }}
                        />
                    </p>
                    {isRDVSurplusOffered ? (
                        isRDVSurplus ? (
                            <div className="relative flex overflow-hidden rounded-lg border border-solid border-green-300 bg-green-50 text-sm">
                                <div className="flex self-stretch bg-green-200 px-1 text-green-700">
                                    <p className="my-auto text-sm">
                                        {(rdvSurplus?.surplus_percentage ?? 0) >
                                        0
                                            ? '+ ' +
                                              rdvSurplus?.surplus_percentage +
                                              '%'
                                            : '+ ' +
                                              getRDVAmount(
                                                  rdvSurplus?.surplus_amount ??
                                                      0
                                              )}
                                    </p>
                                </div>
                                <div className="mr-3 self-stretch text-green-200">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 -15 4 15"
                                        className="h-full"
                                    >
                                        <path
                                            d="M 0 0 L 0 -15 L 4 -15 L 0 0"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </div>

                                <p className="relative pr-2 text-base font-bold">
                                    {totalRDVSurplus()}
                                </p>
                            </div>
                        ) : (
                            <div className="rounded-lg border-solid border-grey-4 bg-neutral-200 px-2 text-base font-light text-neutral-700">
                                <p>{returnTotalAmount}</p>
                            </div>
                        )
                    ) : null}
                </div>
                <div>
                    {keepYourItemOffered ? (
                        <span className="text-xs text-grey-2">
                            {translate('kyi_refund_of')} {returnTotalAmount}{' '}
                            {instantMethod && displayShippingTag ? null : (
                                <span className="text-orange-400">
                                    ( {translate('kyi_shipping_costs')})
                                </span>
                            )}
                        </span>
                    ) : null}
                </div>
                <p
                    className="mt-2 font-normal"
                    style={{
                        color: selected
                            ? theme.colors.common.black
                            : theme.colors.grey[0]
                    }}
                    dangerouslySetInnerHTML={{
                        __html: translate(
                            `refund_card_method${method.method}_description${method.timing}`
                        )
                    }}
                />

                <div className="flex justify-between md:gap-6">
                    <div />
                    {method.timing === RefundTimings.OnStartReturnProcess ? (
                        <Tag
                            color={tagColor}
                            bgroundColor={tagBackgroundColor}
                            selected={selected}
                        >
                            <TagText
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        `refund_card_time_method${method.method}`
                                    )
                                }}
                            />
                        </Tag>
                    ) : (
                        <div />
                    )}
                </div>
            </Card>
        </div>
    )
}

export default RefundCard

const GiftCardIcon = styled(GiftCard)<{ selected: boolean }>`
    @keyframes spin {
        0% {
            transform: translateY(0) rotate(0deg);
            transform-origin: right;
        }
        50% {
            transform: rotate(30deg);
            transform-origin: right;
        }
        100% {
            transform: translateY(0) rotate(0deg);
            transform-origin: right;
        }
    }
    #TOP {
        animation: ${(p) => (p.selected ? 'spin 0.5s linear' : '')};
    }
    z-index: 5;
    height: 15px;
    width: 15px;
    overflow: visible;
`

const Icon = styled.img<{ selected: boolean }>`
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(-30deg);
        }
        100% {
            transform: rotate(0deg);
        }
    }
    animation: ${(p) => (p.selected ? 'spin 0.5s linear' : '')};

    height: 15px;
    width: 15px;
`

const FreeShippingLabel = styled.div`
    position: absolute;
    top: -10px;
    left: 16px;
    padding: 0.1rem 0.8rem;
    border: 1px solid #1b75eb;
    border-radius: 20px;
    background: radial-gradient(circle at 78px 10px, #0076e7, #07a5fe 100%);
    color: white;
`

interface TagProps {
    color: string
    bgroundColor: string
    selected: boolean
    isAnimating?: boolean
}

const Tag = styled.div<TagProps>`
    @keyframes slideInFromLeft {
        from {
            width: 88px;
        }
        to {
            width: 100px;
        }
    }
    @keyframes slideOutFromLeft {
        from {
            width: 100px;
        }
        to {
            width: 88px;
        }
    }

    width: 80px;
    align-self: center;
    margin-top: 8px;
    white-space: nowrap;
    background-color: ${(p) => p.bgroundColor};
    color: ${(p) => p.color};
    text-align: center;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    padding: 3px 0;
    margin-right: ${(p) => (p.selected ? '-10px' : '-12px')};
    transform-origin: right;
    animation: ${(p) =>
        p.selected
            ? `slideInFromLeft 0.2s ease-in-out forwards`
            : 'slideOutFromLeft 0.2s ease-in-out forwards'};
`

const TagText = styled.p`
    font-size: 0.6rem;
    line-height: 8px;
`

interface CardProps {
    selected: boolean
    isBlueOption: boolean
    borderColor: string
    bgColor: string
}

const Card = styled.div<CardProps>`
    padding: ${(p) => (p.selected ? '12px 10px' : '14px 12px')};
    cursor: pointer;
    border: ${(p) => (p.selected ? '2px' : '0px')} solid;
    border-radius: 0.25rem;
    width: 100%;
    margin-bottom: 1.5rem;
    border-color: ${(p) =>
        p.selected ? (p.isBlueOption ? '#1b75eb' : p.borderColor) : ''};
    background-color: ${(p) =>
        p.selected
            ? p.isBlueOption
                ? 'rgb(27, 117, 235, 0.1)'
                : p.bgColor
            : ''};
    box-shadow: ${(p) =>
        p.selected ? '' : '0px 0px 2px 2px rgb(0 0 0 / 0.1)'};
`

import { useAppSelector } from '@/redux/hooks'
import { PoweredByRever } from '@itsrever/design-system'
import { usePages } from '@/hooks'
import { device } from '@/utils'
import styled from 'styled-components'

export const Footer: React.FC = () => {
    const { currentPage } = usePages()

    const FooterContent = (
        <div className="flex w-full items-center justify-center py-4">
            <PoweredByRever />
        </div>
    )

    const isTesting = useAppSelector(
        (store) => store.settingsApi.settings.response.is_testing_account
    )
    const orderNumber = useAppSelector((store) => store.order.orderNumber)
    const isOrderTracking = orderNumber === 'TRACK123' && isTesting

    const FooterToUse = () => {
        if (isOrderTracking) return <></>
        switch (currentPage) {
            case 'ItemSelection':
                return <></>
            case 'Success':
                return <OtherFooter>{FooterContent}</OtherFooter>
            default:
                return <GenericFooter>{FooterContent}</GenericFooter>
        }
    }

    return <>{FooterToUse()}</>
}

const GenericFooter = styled.div`
    width: 100%;
    margin-top: auto;
    @media ${device.lg} {
        background-color: #f2f2f2;
    }
`

const OtherFooter = styled.div`
    width: 100%;
    margin-top: auto;
`

import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { postProcess, resetProcess } from '@/redux/api/returnsApiSlice'
import { toast } from '@itsrever/design-system'
import { useNavigate } from 'react-router-dom'
import { captureEventPosthog } from '@/services/Posthog'
import { setSelectedProcessID } from '@/redux/order/orderSlice'
import { usePages } from '@/hooks'
import { ApiProcessRequest } from '@itsrever/returns-api-types'
import { AxiosError } from 'axios'
import { ItemActions, RefundActions } from '@/redux/return/returnSlice'
import { PbECommerceSettingsRefundExchangeFlowEnum } from '@itsrever/rever-api'
import { ExchangeFlows } from '@/utils/types'

export function useProcess() {
    const { i18n, t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pages } = usePages()

    const processCall = useAppSelector((store) => store.returnsApi.process)
    const process = processCall.response
    const loadingProcess = processCall.loading
    const errorProcess = processCall.error

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const ecommerceID = settings.ecommerce_id
    const userPreferredLang = i18n.language

    const returnProgress = useAppSelector((store) => store.return)
    const iban = returnProgress.IBAN.replace(/\s/g, '')
    const swift = returnProgress.SWIFT
    const bban = returnProgress.BBAN
    const countryCode = returnProgress.countryCode
    const isKeepYourItem = returnProgress.keepYourItem

    const refundMethods = useAppSelector(
        (store) => store.refundMethodsApi.refundMethods.response
    )
    const returnsApi = useAppSelector((store) => store.returnsApi)
    const order = returnsApi.order.response
    const calculate = returnsApi.calculate.response

    const signedReturnSummaryRaw = calculate.signed_summary_raw
    const signedRefundMethodsRaw = refundMethods.result_raw
    const fraudResult = refundMethods.result?.fraud_result
    const checkoutId = order.checkout_id ?? ''

    const orderInfo = useAppSelector((store) => store.order)

    const returnedItems = useAppSelector((store) => store.return.returnedItems)
    const returnedItemsCount = returnedItems.reduce((acc, item) => {
        if (item.item.action === RefundActions.ToReturn)
            return acc + item.item.quantity
        return acc
    }, 0)

    const exchangedOneOnOneItemsCount = returnedItems.reduce((acc, item) => {
        if (item.action === ItemActions.ToExchangeOneOnOne)
            return acc + item.item.quantity
        return acc
    }, 0)

    const exchangedFullCatalogItemsCount = returnedItems.reduce((acc, item) => {
        if (item.action === ItemActions.ToExchangeFullCatalog)
            return acc + item.item.quantity
        return acc
    }, 0)

    const exchangeFlow = toExchangeOptionExchangeFlow(
        settings.refund?.exchange_flow
    )

    const callProcess = () => {
        if (!ecommerceID) return

        const returnData: ApiProcessRequest = {
            iban,
            country_code: countryCode,
            swift,
            bban,
            user_preferred_lang: userPreferredLang,
            signed_return_summary_raw: signedReturnSummaryRaw,
            signed_refund_methods_raw: signedRefundMethodsRaw,
            fraud_result: fraudResult,
            checkout_id: checkoutId
        }
        dispatch(
            postProcess({
                ecommerceID,
                returnData
            })
        )
    }

    async function handleSuccess() {
        dispatch(resetProcess())

        dispatch(setSelectedProcessID(process.pid ?? ''))

        if (process.payment_session_id) return
        if (!settings.is_testing_account) {
            captureEventPosthog('Completed', {
                returnedItemsCount,
                exchangedOneOnOneItemsCount,
                exchangedFullCatalogItemsCount,
                exchangeFlow,
                isKeepYourItem
            })
        }

        navigate(pages.SuccessTracking)
    }

    function handleReject() {
        dispatch(resetProcess())
        console.warn(errorProcess)
        const error = errorProcess as AxiosError

        if (error.response?.status === 400) {
            toast({
                text: t('toast_errors.confirmation_400'),
                variant: 'error'
            })
        }

        if (error.response?.status === 406) {
            toast({
                text: t('toast_errors.error_406'),
                variant: 'error'
            })
            return
        }

        if (error.response?.status === 409) {
            toast({
                text: t('toast_errors.confirmation_409'),
                variant: 'error'
            })
            return
        }

        if (error.response?.status === 401 || error.response?.status === 419) {
            toast({
                text: t('toast_errors.error_419'),
                variant: 'error'
            })
            navigate(
                `${pages.Landing}?orderNumber=${encodeURIComponent(
                    orderInfo.orderNumber || ''
                )}&email=${encodeURIComponent(orderInfo.email || '')}`
            )
            return
        }

        if (error.response?.status === 503) {
            toast({
                text: t('toast_errors.confirmation_503'),
                variant: 'error'
            })
        }

        navigate(pages.Error)
    }

    useEffect(() => {
        if (loadingProcess === 'succeeded') {
            handleSuccess()
            return
        }

        if (loadingProcess === 'failed') {
            handleReject()
            return
        }
    }, [loadingProcess])

    return { callProcess, loadingProcess: loadingProcess === 'pending' }
}

function toExchangeOptionExchangeFlow(
    exchangeFlow: PbECommerceSettingsRefundExchangeFlowEnum | undefined
): (typeof ExchangeFlows)[keyof typeof ExchangeFlows] {
    switch (exchangeFlow) {
        case PbECommerceSettingsRefundExchangeFlowEnum.Open:
            return ExchangeFlows.Open
        case PbECommerceSettingsRefundExchangeFlowEnum.OpenWithGiftCard:
            return ExchangeFlows.OpenWithGiftCard
        case PbECommerceSettingsRefundExchangeFlowEnum.OneOnOne:
            return ExchangeFlows.OneOnOne
        case PbECommerceSettingsRefundExchangeFlowEnum.Full:
            return ExchangeFlows.Full
        default:
            return ExchangeFlows.None
    }
}

import { useAppSelector } from '@/redux/hooks'
import { Button, Modal, toast } from '@itsrever/design-system'
import Summary from '@/components/Summary'
import IntroduceIban from '@/components/IntroduceIban'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
    LineItemSubtypes,
    RefundPaymentMethod,
    ReturnMethod
} from '@/redux/return/returnSlice'
import { useProcess, useTranslate, usePages } from '@/hooks'
import styled from 'styled-components'
import { device, formatPrice } from '@/utils'
import TopBar from '@/components/TopBarComponent/TopBar'
import { StripeCheckout } from '@/components/Checkout/StripeCheckout'
import { ReverCard } from '@/components/ReverCard'
import { captureEventPosthog } from '@/services/Posthog'
import { LoadingModal } from '@/components/Loading'
import { SummaryLite } from '@/components/ReverLite/SummaryLite'
import { PbECommerceSettingsUseCaseEnum } from '@itsrever/rever-api'

function ConfirmationPage() {
    const { translate } = useTranslate()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { pages } = usePages()

    const clientSecret = useAppSelector(
        (store) => store.returnsApi.process.response.payment_session_id
    )

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const isReverLite =
        settings.use_case === PbECommerceSettingsUseCaseEnum.Lite
    const returnsApi = useAppSelector((store) => store.returnsApi)

    const isKeepYourItem = useAppSelector((store) => store.return.keepYourItem)
    const method = useAppSelector((store) => store.return.refundPaymentMethod)

    const { callProcess, loadingProcess } = useProcess()

    const returnProcess = useAppSelector((store) => store.return)

    const calculate = returnsApi.calculate.response
    useEffect(() => {
        if (!calculate.signed_summary_raw) {
            toast({
                text: t('toast_errors.lost_order'),
                variant: 'error'
            })
            navigate(pages.Landing)
        }
    }, [])

    const summary = calculate.signed_summary?.return_summary

    const totalRefundAmount = summary?.total_refund_amount ?? 0
    const totalPaymentAmount = summary?.total_payment_amount ?? 0
    const RDVSurplus = summary?.line_items.find(
        (item) => item.subtype === LineItemSubtypes.RdvSurplus
    )

    const returnMethod = returnProcess.returnMethod
    const refundMethod =
        summary?.refund_method ?? RefundPaymentMethod.NoPaymentMethod

    const needIBAN =
        refundMethod === RefundPaymentMethod.BankTransfer &&
        totalRefundAmount > 0

    // IBAN logic
    const [isIntroduceIbanOpen, setIsIntroduceIbanOpen] =
        useState<boolean>(false)

    const orderMoneyFormat =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        ) ?? {}

    const handleEndProcess = () => {
        if (totalPaymentAmount > 0) {
            if (!settings.is_testing_account) {
                captureEventPosthog('Checkout', {
                    Amount: -totalPaymentAmount,
                    Currency: orderMoneyFormat.currency ?? 'unknown'
                })
            }
        }
        callProcess()
    }

    useEffect(() => {
        if (totalPaymentAmount > 0)
            captureEventPosthog('Confirmation - Negative Amount', {
                Amount: -totalPaymentAmount,
                Currency: orderMoneyFormat.currency ?? 'unknown'
            })
    }, [])

    const calculatedSummary = useAppSelector(
        (store) => store.returnsApi.calculate.response.calculated_summary
    )
    const returnedItems = calculatedSummary?.returned_items ?? []
    const exchangeItems = calculatedSummary?.exchange_items ?? []
    const costs = calculatedSummary?.costs ?? []

    const isPayForLogistics = totalRefundAmount > 0 && totalPaymentAmount > 0
    const confirmationButtonText = isPayForLogistics
        ? t('confirmation.pay_return_costs') +
          ' (' +
          formatPrice(totalPaymentAmount, orderMoneyFormat) +
          ')'
        : totalPaymentAmount > 0
        ? translate('confirmation_page_button_checkout')
        : translate('confirmation_page_button_complete')

    const displayRefundMethod =
        refundMethod !== RefundPaymentMethod.NoPaymentMethod &&
        totalRefundAmount > 0

    return (
        <>
            {loadingProcess ? <LoadingModal loading /> : null}
            <TopBar
                page="confirm"
                title={translate('confirmation_page_title')}
            />
            <GeneralDiv>
                <LeftCard>
                    <HiddenSummaryPart show={clientSecret ? 'true' : 'false'}>
                        {returnMethod !== ReturnMethod.NoReturnMethod ? (
                            <div>
                                <span className="text-xs text-grey-2">
                                    {translate(
                                        'confirmation_page_return_method'
                                    )}
                                </span>
                                <span
                                    className="text-xs text-common-black"
                                    dangerouslySetInnerHTML={{
                                        __html: translate(
                                            `return_card_method${returnMethod}_title`
                                        )
                                    }}
                                />
                            </div>
                        ) : null}
                        {displayRefundMethod && (
                            <div>
                                <span className="text-xs text-grey-2">
                                    {translate(
                                        'confirmation_page_refund_method'
                                    )}
                                </span>
                                <span className="text-xs text-common-black">
                                    {translate(
                                        `confirmation_page_method${refundMethod}`
                                    )}
                                </span>
                            </div>
                        )}
                    </HiddenSummaryPart>
                    {isReverLite ? (
                        <SummaryLite
                            returnedItems={returnedItems}
                            costs={costs}
                        />
                    ) : (
                        <Summary
                            returnedItems={returnedItems}
                            exchangeItems={exchangeItems}
                            costs={costs}
                            totalRefundAmount={totalRefundAmount}
                            totalPaymentAmount={totalPaymentAmount}
                            RDVSurplus={RDVSurplus}
                            refundMethod={method}
                            isKeepYourItem={isKeepYourItem}
                        />
                    )}

                    {!needIBAN && !clientSecret ? (
                        <div className="mt-4 flex w-full flex-col content-center items-center lg:mt-8">
                            <div className="w-full md:max-w-[280px]">
                                <Button
                                    id="confirmButton"
                                    fullWidth
                                    onClick={handleEndProcess}
                                >
                                    {confirmationButtonText}
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </LeftCard>
                {clientSecret ? (
                    <RightBox>
                        <StripeCheckout />
                    </RightBox>
                ) : null}

                {needIBAN && (
                    <RightBox>
                        <BigIbanDiv>
                            <IntroduceIban onContinue={handleEndProcess} />
                        </BigIbanDiv>
                        <ButtonDiv>
                            <div className="w-full sm:w-fit">
                                <Button
                                    fullWidth
                                    onClick={() => setIsIntroduceIbanOpen(true)}
                                >
                                    {translate(
                                        'confirmation_page_button_complete'
                                    )}
                                </Button>
                            </div>
                        </ButtonDiv>
                        <CustomModal
                            isOpen={isIntroduceIbanOpen}
                            onRequestClose={() => setIsIntroduceIbanOpen(false)}
                        >
                            <IntroduceIban
                                onContinue={() => {
                                    handleEndProcess()
                                    setIsIntroduceIbanOpen(false)
                                }}
                            />
                        </CustomModal>
                    </RightBox>
                )}
            </GeneralDiv>
        </>
    )
}

const LeftCard = styled(ReverCard)`
    @media (max-width: 899px) {
        max-width: 20rem;
    }
`

const HiddenSummaryPart = styled.div<{ show: string }>`
    align-self: flex-start;
    margin-bottom: 1rem;
    @media (max-width: 899px) {
        display: ${(p) => (p.show === 'true' ? 'none' : '')};
    }
`

const RightBox = styled.div`
    background: #ffffff;
    margin-top: 1.5rem;
    max-width: 20rem;
    z-index: 9;
    @media ${device.lg} {
        max-width: 23rem;
        margin-top: 2.5rem;
        padding: 1.5rem 2rem 1.5rem 2rem;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
    }
`

const GeneralDiv = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media ${device.lg} {
        background-color: #f2f2f2;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
    }
`

const CustomModal = styled(Modal)`
    // Over lg
    @media ${device.lg} {
        display: none;
    }
`

const ButtonDiv = styled.div`
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: center;
    @media (max-width: 899px) {
        width: 100%;
    }
    //Over lg
    @media ${device.lg} {
        display: none;
    }
`

const BigIbanDiv = styled.div`
    //Below lg
    @media (max-width: 899px) {
        display: none;
    }
`

export default ConfirmationPage

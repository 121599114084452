import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
    cleanCalculate,
    cleanProcess,
    getOrder,
    resetOrder
} from '@/redux/api/returnsApiSlice'
import { cleanRefundMethods } from '@/redux/api/refundMethodsApiSlice'
import {
    resetReturn,
    setExchangeAddress,
    setReturnAddress
} from '@/redux/return/returnSlice'
import {
    setOrderProducts,
    setEmail,
    setOrderNumber
} from '@/redux/order/orderSlice'
import { toast } from '@itsrever/design-system'
import { setTagsSentry, setUserSentry } from '@/services/Sentry'
import { usePages, useSlug } from '@/hooks'
import { AxiosError } from 'axios'
import { captureEventPosthog, setUserPosthog } from '@/services/Posthog'
import { cleanReturnMethods } from '@/redux/api/returnMethodsApiSlice'
import { PbECommerceSettingsUseCaseEnum } from '@itsrever/rever-api'

export function useOrder(
    disableNavigate?: boolean,
    setErrorOrder?: (isError: boolean) => void
) {
    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pages } = usePages()
    const slug = useSlug()

    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )

    const orderCall = useAppSelector((store) => store.returnsApi.order)

    const order = orderCall.response
    const loadingOrder = orderCall.loading
    const errorOrderCall = orderCall.error

    const ecommerceID = settings.ecommerce_id

    const orderNumber = useAppSelector((store) => store.order.orderNumber)
    const email = useAppSelector((store) => store.order.email)

    const isReverLite =
        settings.use_case === PbECommerceSettingsUseCaseEnum.Lite

    const callOrder = (customerPrintedOrderId: string, email: string) => {
        let ecommerceID = settings.ecommerce_id
        if (customerPrintedOrderId === 'REVER123') {
            ecommerceID = 'partner123'
            customerPrintedOrderId = 'N1234'
        }
        if (!ecommerceID) return

        dispatch(setOrderNumber(customerPrintedOrderId))
        dispatch(setEmail(email))

        dispatch(
            getOrder({
                ecommerceId: ecommerceID,
                orderId: customerPrintedOrderId,
                email,
                userPreferredLang: i18n.language
            })
        )
    }

    function handleSuccess() {
        dispatch(resetOrder())

        dispatch(cleanRefundMethods())
        dispatch(cleanReturnMethods())
        dispatch(cleanCalculate())
        dispatch(cleanProcess())

        dispatch(resetReturn())

        const orderLineItems = order?.order?.line_items
        if (!isReverLite) dispatch(setOrderProducts(orderLineItems))

        const address = order?.order?.shipping_address
        if (address) {
            dispatch(setReturnAddress(address))
            dispatch(setExchangeAddress(address))
        }

        const customer = order?.order?.customer

        if (!settings.is_testing_account) {
            setUserPosthog(email, customer?.first_name, customer?.last_name)
            captureEventPosthog('Started', { orderNumber })
        }

        setUserSentry(email)
        setTagsSentry({
            ecommerceID: ecommerceID ?? '',
            orderNumber: orderNumber ?? '',
            email: email ?? '',
            portalURL: `https://returns.byrever.com/${slug}?orderNumber=${encodeURIComponent(
                orderNumber ?? ''
            )}&email=${encodeURIComponent(email ?? '')}`
        })

        if (disableNavigate) return
        if (orderNumber === 'TRACK123' && settings.is_testing_account) {
            navigate(`/${slug}/order-tracking`)
            return
        }
        if (
            order.previous_return_processes &&
            order.previous_return_processes.length > 0
        ) {
            navigate(pages.ProcessSelection)
            return
        }

        navigate(pages.ItemSelection)
    }

    function handleReject() {
        dispatch(resetOrder())
        console.warn(errorOrderCall)
        const error = errorOrderCall as AxiosError

        if (error.response?.status === 404) {
            if (setErrorOrder) setErrorOrder(true)
            return
        }

        if (error.response?.status === 406) {
            toast({
                text: t('toast_errors.error_406'),
                variant: 'error'
            })
            return
        }

        if (error.response?.status === 503) {
            toast({
                text: t('toast_errors.confirmation_503'),
                variant: 'error'
            })
        }
        navigate(pages.Error)
    }

    useEffect(() => {
        if (loadingOrder === 'succeeded') {
            handleSuccess()
            return
        }

        if (loadingOrder === 'failed') {
            handleReject()
            return
        }
    }, [loadingOrder])

    return {
        callOrder,
        loadingOrder: loadingOrder === 'pending'
    }
}

import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'
import {
    useTheme,
    CoinIcon,
    RepeatIcon,
    CrossIcon
} from '@itsrever/design-system'
import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { useState } from 'react'
import ProductPreview from '../ProductPreview'
import {
    ItemActions,
    RefundActions,
    removeExchangedItem,
    removeReturnedItem
} from '@/redux/return/returnSlice'
import styled from 'styled-components'
import { device } from '@/utils'
import { useTranslate } from '@/hooks'
import { SelectionFlow } from '@/components/ItemSelectionComponents'

export interface ProductCardProps {
    product: ModelsLineItem
    subProductId: number
}

export const ProductCard: React.FC<ProductCardProps> = ({
    product,
    subProductId
}) => {
    const { translate } = useTranslate()
    const dispatch = useAppDispatch()
    const theme = useTheme()

    const returnProgress = useAppSelector((store) => store.return)
    const returnedItems = returnProgress.returnedItems

    const returnedItem = returnedItems.find(
        (retItem) =>
            retItem.item.line_item_id === product.id &&
            retItem.subProductId === subProductId
    )
    const quantity = returnedItem?.item.quantity

    const isExchanged = returnedItem?.item.action === RefundActions.ToExchange
    const isExchangeFullCatalog =
        returnedItem?.action === ItemActions.ToExchangeFullCatalog
    const isSelected = returnedItem ? true : false

    const matchingExchangeSelection = returnProgress.exchangeSelections.find(
        (selection) =>
            selection.originalItemSubproductId === subProductId &&
            selection.exchangeSelection.line_item_platform_id === product.id
    )

    const [isSelectionFlowOpen, setIsSelectionFlowOpen] = useState(false)

    const handleItems = () => {
        dispatch(
            removeReturnedItem({
                lineItemId: product.id ?? ''
            })
        )
        if (isExchanged) {
            dispatch(
                removeExchangedItem({
                    lineItemId: product.id ?? ''
                })
            )
        }
    }

    const handleClose = () => {
        setIsSelectionFlowOpen(false)
    }

    const handleClickCard = () => {
        if (isSelected) return
        setIsSelectionFlowOpen(true)
    }

    return (
        <>
            <Card
                selected={isSelected}
                borderColor={theme.colors.grey[2]}
                onClick={handleClickCard}
            >
                <ProductPreview
                    product={product}
                    selectedQuantity={isSelected ? quantity : undefined}
                />

                {isSelected ? (
                    <>
                        <SelectedBox>
                            {isExchanged ? (
                                <>
                                    <ExchangeVariantText
                                        className="mr-2 text-xs lg:text-sm"
                                        dangerouslySetInnerHTML={{
                                            __html: product.variant_name ?? ''
                                        }}
                                    />
                                    <RepeatIcon
                                        color={theme.colors.common.black}
                                    />
                                    <ExchangeVariantText className="ml-2 text-xs lg:text-sm">
                                        {isExchangeFullCatalog
                                            ? matchingExchangeSelection?.productName
                                            : null}{' '}
                                        {matchingExchangeSelection?.variantName ??
                                            ''}
                                    </ExchangeVariantText>
                                </>
                            ) : (
                                <>
                                    <ExchangeVariantText
                                        className="mr-2 text-xs lg:text-sm"
                                        dangerouslySetInnerHTML={{
                                            __html: translate('icons_return')
                                        }}
                                    />
                                    <CoinIcon
                                        color={theme.colors.common.black}
                                    />
                                </>
                            )}
                        </SelectedBox>

                        <CrossBox
                            id="crossbox"
                            color={theme.colors.grey[3]}
                            onClick={(e) => {
                                e.stopPropagation()
                                handleItems()
                            }}
                        >
                            <CrossIcon color={theme.colors.grey[1]} />
                        </CrossBox>
                    </>
                ) : null}
            </Card>

            {isSelectionFlowOpen ? (
                <SelectionFlow
                    product={product}
                    subProductId={subProductId}
                    isOpen={isSelectionFlowOpen}
                    handleClose={handleClose}
                />
            ) : null}
        </>
    )
}

const ExchangeVariantText = styled.span`
    max-width: 45%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0.15rem;
    border-radius: 0.25rem;
`

const SelectedBox = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    flex-grow: 1;

    @media (max-width: 899px) {
        padding: 0rem 1rem 0rem 1rem;
    }
    @media ${device.lg} {
        width: 30%;
        align-items: center;
        align-content: center;
        height: fit-content;
    }
`

const Card = styled.div<{ selected: boolean; borderColor: string }>`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem 0;
    width: 100%;
    border: 1px solid;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-color: ${(p) => (p.selected ? p.borderColor : 'transparent')};
    background-color: white;
    cursor: ${(p) => (p.selected ? 'auto' : 'pointer')};
    @media ${device.lg} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media ${device.xl} {
        padding: 2rem 0;
    }
`

interface CrossBoxProps {
    color: string
}

const CrossBox = styled.div<CrossBoxProps>`
    height: fit-content;
    // Below 900px
    @media (max-width: 899px) {
        position: absolute;
        right: 0px;
        top: 0px;
        margin-right: 1rem;
        margin-top: 1rem;
    }

    cursor: pointer;
    &:hover {
        background-color: ${(p) => p.color};
    }
    // Over lg
    @media ${device.lg} {
        padding: 0.375rem;
        margin-right: 2rem;
        margin-left: 0.5rem;
        border-radius: 9999px;
    }
`

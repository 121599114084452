import { getInstructions } from '@/api/trackingApi'
import { toast } from '@itsrever/design-system'
import { useAppSelector } from '@/redux/hooks'
import { captureEventPosthog } from '@/services/Posthog'

export function useDownloadInstructions() {
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const isTesting = settings?.is_testing_account

    async function downloadInstructions(processId: string, email: string) {
        if (!isTesting) captureEventPosthog('Download Instructions')

        const data = await getInstructions({ process_id: processId, email })
        if (data === 404) {
            toast({
                text: 'Instructions not found!',
                variant: 'error'
            })
            return
        }
        if (typeof data !== 'number' && data) {
            if (
                /iphone/i.test(navigator.userAgent) ||
                /ipad/i.test(navigator.userAgent)
            ) {
                window.location.href = data.url ?? ''
            } else {
                window.open(data.url, '_blank')
            }
            return
        }
        toast({
            text: 'Something went wrong! Please try again later',
            variant: 'error'
        })
    }

    return { downloadInstructions }
}

import { useAppSelector } from '@/redux/hooks'
import { getProductDetails } from '@/redux/api/storeApiSlice'
import { ReturnsApiProductDetailsRequest } from '@itsrever/rever-api'
import { useTranslation } from 'react-i18next'

export function useStoreProductDetails() {
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const { i18n } = useTranslation()
    const countryCode =
        useAppSelector((store) => store.return.returnAddress.country_code) ??
        'ES'
    const language = i18n.language.toLocaleUpperCase() ?? 'EN'
    const ecommerceId = settings.ecommerce_id ?? ''

    const currency =
        useAppSelector(
            (store) => store.returnsApi.order.response?.order?.money_format
        )?.currency ?? 'unknown'

    const fetchProductDetails = async (productId: string) => {
        const details = {
            ecommerceId,
            productId,
            language,
            currency,
            countryCode
        } as ReturnsApiProductDetailsRequest
        return getProductDetails(details)
    }

    return {
        fetchProductDetails
    }
}

import { ChevronDownIcon, TextInput, useTheme } from '@itsrever/design-system'
import styled from 'styled-components'
import ReverLiteCounter from './ReverLiteCounter'
import { device, lessThan } from '@/utils'
import { useMediaQuery } from '@mui/material'
import { useTranslate } from '@/hooks'
import DeleteIcon from '@/assets/images/icons/DeleteIcon'
import { useTranslation } from 'react-i18next'

interface Product {
    name: string
    reason: string
    quantity: number
}

type AddProductCardProps = {
    product: Product
    onRemove: () => void
    onClickReason: () => void
    updateProduct: (newProduct: Product) => void
}

const AddProductCard: React.FC<AddProductCardProps> = ({
    product,
    onRemove,
    onClickReason,
    updateProduct
}) => {
    const theme = useTheme()
    const { translate } = useTranslate()
    const { t } = useTranslation()

    const updateProductName = (name: string) => {
        updateProduct({ ...product, name: name })
    }
    const updateQuantity = (quantity: number) => {
        updateProduct({ ...product, quantity: quantity })
    }
    const isMobile = useMediaQuery(lessThan.md)

    return (
        <>
            <Card
                className="mt-2"
                style={{ border: `1px solid ${theme.colors.primary.main}` }}
            >
                <div
                    onClick={() => onRemove()}
                    className="absolute right-1 top-1 flex size-6 cursor-pointer items-center justify-center text-grey-2"
                >
                    <DeleteIcon />
                </div>
                <div className="flex w-full flex-col xl:px-4">
                    <label className="text-sm text-grey-3">
                        {t('rever_lite.product_name')}
                    </label>
                    <TextInput
                        fullWidth={isMobile ? true : false}
                        placeholder={t('rever_lite.product_name_placeholder')}
                        value={product.name}
                        onChange={(e: React.FormEvent<HTMLInputElement>) =>
                            updateProductName(e.currentTarget.value)
                        }
                    />
                    <div className="grid w-full grid-cols-3 items-center justify-between gap-4">
                        <div className=" col-span-2 w-full">
                            <label className="text-sm text-grey-3">
                                {t('rever_lite.return_reason')}
                            </label>
                            <div
                                onClick={() => onClickReason()}
                                className="mt-1 rounded border border-solid border-grey-4 p-3"
                            >
                                <div className="flex items-center justify-between ">
                                    {product.reason ? (
                                        <p
                                            className="w-full truncate text-left"
                                            dangerouslySetInnerHTML={{
                                                __html: translate(
                                                    `select_reason_${product.reason}`
                                                )
                                            }}
                                        />
                                    ) : (
                                        <p className="text-grey-2">
                                            {t(
                                                'rever_lite.return_reason_placeholder'
                                            )}
                                        </p>
                                    )}
                                    <div className="shrink-0">
                                        <ChevronDownIcon
                                            color={theme.colors.grey[3]}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 flex w-full flex-col items-center justify-center gap-3 self-start">
                            <label
                                className="text-sm text-grey-3"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        'product_preview_quantity'
                                    )
                                }}
                            />
                            <ReverLiteCounter
                                min={1}
                                onUpdate={updateQuantity}
                            />
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}
export default AddProductCard

const Card = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1rem;
    width: 100%;
    border: 1px solid;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border-color: transparent;
    background-color: white;
    @media ${device.lg} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    @media ${device.xl} {
        padding: 2rem 0;
    }
`

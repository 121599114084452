import { Button, ChevronLeftIcon, useTheme } from '@itsrever/design-system'
import { ReturnsProductDetailsResponseVariantInfo } from '@itsrever/rever-api'
import { Skeleton } from '@mui/material'
import React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAppSelector } from '@/redux/hooks'
import { formatProductPrice } from '@/components/FullCatalog/utils'

interface MobileVariantSelectorProps {
    name: string
    description?: string
    imageUrl?: string
    variants: Array<ReturnsProductDetailsResponseVariantInfo>
    selectedVariantIndex: number | undefined
    onVariantSelected: (variantIndex: number | undefined) => void
    loadingDetails: boolean
    onConfirmVariantSelection: () => void
    onCloseVariantSelector: () => void
    productPhotoList: Array<string>
}

export const MobileVariantSelector: React.FC<MobileVariantSelectorProps> = ({
    name,
    description,
    imageUrl,
    variants,
    selectedVariantIndex,
    onVariantSelected,
    loadingDetails,
    onConfirmVariantSelection,
    onCloseVariantSelector,
    productPhotoList
}) => {
    const theme = useTheme()
    const { t, i18n } = useTranslation()
    const lang = i18n.language.toLocaleUpperCase() ?? 'EN'

    const [mainImageUrl, setMainImageUrl] = useState<string>(imageUrl ?? '')
    useEffect(() => {
        setMainImageUrl(imageUrl ?? '')
    }, [imageUrl])

    const changeMainImage = (newImage: string) => {
        if (newImage) {
            setMainImageUrl(newImage)
        } else {
            setMainImageUrl(imageUrl ?? '')
        }
    }

    const onVariantClick = (
        variant: ReturnsProductDetailsResponseVariantInfo,
        index: number
    ) => {
        if (!hasStock(variant)) return
        changeMainImage((variant.image_urls && variant.image_urls[0]) ?? '')
        if (selectedVariantIndex === index) {
            onVariantSelected(undefined)
        } else {
            onVariantSelected(index)
        }
    }

    const hasStock = (variant: ReturnsProductDetailsResponseVariantInfo) => {
        return (
            !variant.is_inventory_enabled ||
            (variant.inventory_quantity !== undefined &&
                variant.inventory_quantity >= 1)
        )
    }

    const sortByStock = (
        a: ReturnsProductDetailsResponseVariantInfo,
        b: ReturnsProductDetailsResponseVariantInfo
    ) => {
        const aHasStock = hasStock(a)
        const bHasStock = hasStock(b)
        if (aHasStock && !bHasStock) {
            return -1
        } else if (!aHasStock && bHasStock) {
            return 1
        } else {
            return 0
        }
    }

    const email = useAppSelector((store) => store.order.email) ?? ''
    const emailRegex = /@itsrever\.com/
    const isReverMail = emailRegex.test(email)
    const inlineRegex = /\+inline/
    const isInlineTest = isReverMail && inlineRegex.test(email)

    return (
        <>
            <div className="absolute inset-0 p-4">
                {loadingDetails ? null : (
                    <div className="relative flex h-full min-h-0 flex-1 flex-col overflow-auto">
                        <div className="flex flex-1 flex-col overflow-y-scroll">
                            <div className="shrink-0">
                                <div className="flex">
                                    <button
                                        onClick={onCloseVariantSelector}
                                        className="mr-4 flex shrink-0 cursor-pointer items-center p-2 text-xl "
                                    >
                                        <ChevronLeftIcon
                                            color={theme.colors.grey[2]}
                                        />
                                    </button>

                                    <h4>{name}</h4>
                                </div>
                                <p>{description}</p>
                                <hr className="my-2" />
                            </div>
                            <div className="flex shrink flex-col overflow-auto rounded-lg bg-white p-2">
                                <div className="flex w-full gap-2">
                                    {mainImageUrl && (
                                        <img
                                            className="aspect-square max-h-[60vw] w-full min-w-20 max-w-[60vw] shrink rounded-lg object-cover"
                                            src={mainImageUrl}
                                        />
                                    )}
                                    <div className="flex max-h-[60vw] min-w-12 shrink-0 grow flex-col gap-2 overflow-scroll">
                                        {productPhotoList?.map((photo, i) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {!loadingDetails ? (
                                                        <div
                                                            className="relative w-full overflow-hidden rounded-lg bg-cover bg-center bg-no-repeat pb-[100%]"
                                                            onClick={() =>
                                                                changeMainImage(
                                                                    photo
                                                                )
                                                            }
                                                            style={{
                                                                backgroundImage: `url(${photo})`
                                                            }}
                                                        />
                                                    ) : (
                                                        <Skeleton variant="rounded" />
                                                    )}
                                                </React.Fragment>
                                            )
                                        })}
                                    </div>
                                </div>
                                <p className="mb-1 mt-4">
                                    {t('full_catalog.select_option')}
                                </p>
                                <div className="mt-2 min-h-0 shrink grow overflow-scroll">
                                    {variants
                                        .sort(sortByStock)
                                        .map((variant, i) => {
                                            return (
                                                <VariantDiv
                                                    key={i}
                                                    onClick={() => {
                                                        onVariantClick(
                                                            variant,
                                                            i
                                                        )
                                                    }}
                                                    $selected={
                                                        selectedVariantIndex ===
                                                        i
                                                    }
                                                    $disabled={
                                                        !hasStock(variant)
                                                    }
                                                >
                                                    <div className="flex flex-1 items-center justify-between overflow-hidden p-2">
                                                        <p
                                                            className="
                                                        flex-1 shrink truncate"
                                                        >
                                                            {variant.name ===
                                                            'Default Title'
                                                                ? name
                                                                : variant.name}
                                                        </p>
                                                        {!hasStock(variant) ? (
                                                            <p className="ml-4 text-xs text-slate-600">
                                                                {t(
                                                                    'full_catalog.no_stock'
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <p className="shrink-0 rounded bg-gray-100 px-2 text-sm text-gray-500">
                                                                {!(
                                                                    selectedVariantIndex ===
                                                                        i &&
                                                                    isInlineTest
                                                                ) &&
                                                                    formatProductPrice(
                                                                        variant
                                                                            .price
                                                                            ?.amount,
                                                                        variant
                                                                            .price
                                                                            ?.currency_code,
                                                                        lang
                                                                    )}
                                                            </p>
                                                        )}
                                                    </div>
                                                    {isInlineTest && (
                                                        <VariantConfirm
                                                            onClick={() =>
                                                                onConfirmVariantSelection()
                                                            }
                                                            className="shrink-0 place-items-center p-1"
                                                            $show={
                                                                selectedVariantIndex ===
                                                                i
                                                            }
                                                        >
                                                            <p
                                                                className="mr-2 justify-center overflow-hidden px-3 py-2 text-center align-middle leading-3"
                                                                style={{
                                                                    borderRadius:
                                                                        theme.borderRadius,
                                                                    color: theme
                                                                        .colors
                                                                        .primary
                                                                        .contrastText,
                                                                    backgroundColor:
                                                                        theme
                                                                            .colors
                                                                            .primary
                                                                            .main,
                                                                    display:
                                                                        selectedVariantIndex ===
                                                                        i
                                                                            ? 'flex'
                                                                            : 'none'
                                                                }}
                                                            >
                                                                {t(
                                                                    'full_catalog.confirm_variant'
                                                                )}
                                                                <span className="ml-2">
                                                                    {formatProductPrice(
                                                                        variant
                                                                            .price
                                                                            ?.amount,
                                                                        variant
                                                                            .price
                                                                            ?.currency_code,
                                                                        lang
                                                                    )}
                                                                </span>
                                                            </p>
                                                        </VariantConfirm>
                                                    )}
                                                </VariantDiv>
                                            )
                                        })}
                                </div>
                            </div>
                        </div>
                        {!isInlineTest && (
                            <ButtonContainer
                                $show={selectedVariantIndex !== undefined}
                            >
                                <Button
                                    onClick={() => onConfirmVariantSelection()}
                                >
                                    {t('full_catalog.confirm_variant')}
                                </Button>
                            </ButtonContainer>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}

interface VariantDivProps {
    $selected: boolean
    $disabled: boolean
}

const VariantDiv = styled.div<VariantDivProps>`
    margin-bottom: 0.5rem;
    display: flex;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    height: 2.5rem;
    background-color: ${(p) => (p.$disabled ? '#d1d5db' : '')};
    border-radius: 0.375rem;
    border: ${(p) =>
        p.$selected ? `1px solid var(--brand-600)` : '1px solid #d1d5db'};
    padding: 0rem 0rem 0rem 0.5rem;
    &:hover {
        background-color: '#fff';
    }
`
interface VariantConfirmProps {
    $show: boolean
}

const VariantConfirm = styled.button<VariantConfirmProps>`
    display: grid;
    grid-template-columns: ${(p) => (p.$show ? '1fr' : '0fr')};
    transition: all 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`

interface ButtonContainerProps {
    $show: boolean
}
const ButtonContainer = styled.div<ButtonContainerProps>`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: ${(p) => (p.$show ? '20px' : '-200px')};
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
`

import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { addReturnedItem } from '@/redux/return/returnSlice'
import { ReturnsCreateLiteOrderRequest } from '@itsrever/rever-api'
import { createLiteOrder } from '@/api/returnsApi'
import { setOrder } from '@/redux/api/returnsApiSlice'
import { ProductLite } from '@/utils/types'
import { ApiReturnedItem } from '@itsrever/returns-api-types'
import { useState } from 'react'
import {
    setEmail,
    setOrderNumber,
    setOrderProducts
} from '@/redux/order/orderSlice'
import { clearReturnedItems } from '@/redux/return/returnSlice'
import { captureEventPosthog, setUserPosthog } from '@/services/Posthog'
import { useNavigate } from 'react-router-dom'
import { setTagsSentry, setUserSentry } from '@/services/Sentry'
import { useSlug } from './useSlug'
import { usePages } from './usePages'

export function useOrderLite() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { pages } = usePages()
    const slug = useSlug()
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )

    const ecommerceID = settings.ecommerce_id
    const [isLoading, setIsLoading] = useState(false)

    const callOrderLite = async (
        returnsCreateLiteOrderRequest: ReturnsCreateLiteOrderRequest,
        products: ProductLite[]
    ) => {
        dispatch(clearReturnedItems())

        setIsLoading(true)
        createLiteOrder({
            ecommerceId: ecommerceID ?? '',
            returnsCreateLiteOrderRequest: returnsCreateLiteOrderRequest
        })
            .then((response) => {
                products.forEach((product, i) => {
                    const item: ApiReturnedItem = {
                        line_item_id:
                            response?.data?.order?.line_items?.[i]?.id ?? '',
                        quantity: product.quantity,
                        product_return_reason: product.reason,
                        action: 2
                    }
                    dispatch(
                        addReturnedItem({
                            item,
                            lineItemId:
                                response?.data?.order?.line_items?.[i]?.id ??
                                '',
                            subProductId: 0,
                            action: 'TO_RETURN'
                        })
                    )
                })
                dispatch(setOrder(response.data))
                dispatch(setOrderProducts(products))
            })
            .catch((error) => {
                console.warn('Error creating order', error)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const handleStartLiteOrder = async (
        customerPrintedOrderId: string | undefined,
        email: string | undefined
    ) => {
        let ecommerceID = settings.ecommerce_id
        if (customerPrintedOrderId === 'REVER123') {
            ecommerceID = 'partner123'
            customerPrintedOrderId = 'N1234'
        }
        if (!ecommerceID) return

        dispatch(setOrderNumber(customerPrintedOrderId))
        dispatch(setEmail(email))

        if (!settings.is_testing_account) {
            setUserPosthog(email)
            captureEventPosthog('Started', { customerPrintedOrderId })
        }

        setUserSentry(email)
        setTagsSentry({
            ecommerceID: ecommerceID ?? '',
            orderNumber: customerPrintedOrderId ?? '',
            email: email ?? '',
            portalURL: `https://returns.byrever.com/${slug}?orderNumber=${encodeURIComponent(
                customerPrintedOrderId ?? ''
            )}&email=${encodeURIComponent(email ?? '')}`
        })

        navigate(pages.ItemSelection)
    }

    return {
        callOrderLite,
        isLoading,
        handleStartLiteOrder
    }
}

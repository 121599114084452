import { useTranslate } from '@/hooks'
import { useAppSelector } from '@/redux/hooks'
import { useTheme, Button, ReverLoading } from '@itsrever/design-system'
import { ReturnsLineItem as ModelsLineItem } from '@itsrever/rever-api'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { findItemReturnReasons } from '@/redux/api/settingsApiSlice'
import { PbAvailableReturnReasons } from '@itsrever/rever-api'
import { use100vh } from 'react-div-100vh'
import { useTranslation } from 'react-i18next'
import ProductPreview from '@/components/ProductPreview'
import { Counter } from '@itsrever/design-system'

interface SelectReasonProps {
    product: ModelsLineItem
    selectedQuantity: number
    setSelectedQuantity: (quantity: number) => void
    selectedReason: string
    setSelectedReason: (reason: string) => void
    handleContinue: () => void
    isFullCatalog: boolean
}

export const SelectReason: React.FC<SelectReasonProps> = ({
    product,
    selectedQuantity,
    setSelectedQuantity,
    selectedReason,
    setSelectedReason,
    handleContinue,
    isFullCatalog
}) => {
    const { translate } = useTranslate()
    const theme = useTheme()
    const height = use100vh()
    const { t } = useTranslation()

    let maxQuantity = product.quantity ?? 1
    if (isFullCatalog) maxQuantity = 1

    const [reasonGroups, setReasonGroups] = useState(
        Array<PbAvailableReturnReasons>
    )
    const [subReasons, setSubReasons] = useState(Array<string>)
    const [selectedReasonGroup, setSelectedReasongroup] = useState('')
    const [loadingReasons, setLoadingReasons] = useState(false)

    const onClickReasonGroup = (reasonGroup: string) => {
        const groupSubReasons = reasonGroups.find(
            (group) => group.parent_reason?.name === reasonGroup
        )?.available_reasons

        if (!groupSubReasons) {
            setSelectedReason(reasonGroup)
        } else {
            setSelectedReasongroup(reasonGroup)
        }
    }

    const ecommerceId = useAppSelector(
        (s) => s.settingsApi.settings.response.ecommerce_id
    )

    useEffect(() => {
        setLoadingReasons(true)
        const categoryIds =
            product.product?.categories?.map((category) => category.id ?? '') ??
            []
        findItemReturnReasons(ecommerceId ?? '', categoryIds)
            .then((groups) => {
                setReasonGroups(groups ?? [])
            })
            .finally(() => {
                setLoadingReasons(false)
            })
    }, [])

    useEffect(() => {
        const groupSubReasons = reasonGroups.find(
            (group) => group.parent_reason?.name === selectedReasonGroup
        )?.available_reasons
        const reasons = groupSubReasons?.map((reason) => reason.name ?? '')
        setSubReasons(reasons ?? [])
    }, [selectedReasonGroup])

    if (loadingReasons)
        return (
            <div className="flex h-fit w-full items-center justify-center py-10">
                <ReverLoading />
            </div>
        )

    return (
        <div className="h-fit w-full min-w-[250px] overflow-x-hidden px-2 sm:min-w-[280px]">
            <div
                id="productPreview"
                className="mt-4 flex max-w-full flex-col border-b border-solid border-grey-3 py-4"
            >
                <ProductPreview product={product} />
                {maxQuantity > 1 ? (
                    <div className="mt-4 flex flex-col items-center text-xs">
                        <Counter
                            id="counter"
                            max={maxQuantity}
                            defaultValue={selectedQuantity}
                            setValue={setSelectedQuantity}
                        />
                    </div>
                ) : null}
            </div>
            <div className="h-fit w-full overflow-x-hidden px-2">
                {selectedReasonGroup ? (
                    <p className="my-4">{t('select_subreason_title')}</p>
                ) : (
                    <p
                        className="my-4"
                        dangerouslySetInnerHTML={{
                            __html: translate(`select_reason_title`)
                        }}
                    />
                )}

                <div
                    style={{ maxHeight: (height ?? 500) * 0.6 + 'px' }}
                    className="overflow-y-scroll"
                >
                    {selectedReasonGroup
                        ? subReasons?.map((reason, i) => {
                              return (
                                  <ReasonDiv
                                      className="md:hover:bg-grey-6"
                                      borderCol={theme.colors.grey[2]}
                                      key={i}
                                      id={reason}
                                      data-testid={i}
                                      onClick={() => setSelectedReason(reason)}
                                      style={{
                                          backgroundColor:
                                              selectedReason === reason
                                                  ? theme.colors.grey[4]
                                                  : ''
                                      }}
                                  >
                                      <p
                                          className="w-full text-center md:text-left"
                                          dangerouslySetInnerHTML={{
                                              __html: translate(
                                                  `select_reason_${reason}`
                                              )
                                          }}
                                      />
                                  </ReasonDiv>
                              )
                          })
                        : reasonGroups?.map((reason, i) => {
                              return (
                                  <ReasonDiv
                                      className="md:hover:bg-grey-6"
                                      borderCol={theme.colors.grey[2]}
                                      key={i}
                                      id={reason.parent_reason?.name}
                                      data-testid={i}
                                      onClick={() =>
                                          onClickReasonGroup(
                                              reason?.parent_reason?.name ?? ''
                                          )
                                      }
                                      style={{
                                          backgroundColor:
                                              selectedReason ===
                                              reason?.parent_reason?.name
                                                  ? theme.colors.grey[4]
                                                  : ''
                                      }}
                                  >
                                      <p
                                          className="w-full text-center md:text-left"
                                          dangerouslySetInnerHTML={{
                                              __html: translate(
                                                  `select_reason_${reason.parent_reason?.name}`
                                              )
                                          }}
                                      />
                                  </ReasonDiv>
                              )
                          })}
                </div>

                <div className="mb-8 mt-4 flex w-full flex-col content-center items-center">
                    <div className="w-full sm:w-fit">
                        <Button
                            disabled={!selectedReason}
                            id="reason-continue-button"
                            onClick={handleContinue}
                            fullWidth
                        >
                            {translate('select_reason_button_continue')}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ReasonProps {
    borderCol: string
}

const ReasonDiv = styled.div<ReasonProps>`
    margin-bottom: 0.5rem;
    display: flex;
    width: 100%;
    cursor: pointer;
    border-radius: 0.375rem;
    border: 1px solid;
    border-color: ${(p) => p.borderCol};
    padding: 0.5rem 1rem 0.5rem 1rem;

    //Text not selectable
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
`

import { PbshoppingProductInfoPriceRange } from '@itsrever/rever-api'

export const formatProductPrice = (
    amount: string | undefined,
    currency: string | undefined,
    lang: string
) => {
    const currencyCode = currency ?? 'EUR'
    const amountValue = amount ?? '0'
    return new Intl.NumberFormat(lang, {
        style: 'currency',
        currency: currencyCode
    }).format(parseFloat(amountValue))
}

export const variantsHaveSamePrices = (
    range: PbshoppingProductInfoPriceRange | undefined
) => {
    return (
        parseFloat(range?.min?.amount ?? '0') ===
        parseFloat(range?.max?.amount ?? '0')
    )
}

import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { LessIcon, MoreIcon, useTheme } from '@itsrever/design-system'
interface ReverLiteCounterProps {
    min?: number
    max?: number
    onUpdate?: (value: number) => void
}

const ReverLiteCounter: React.FC<ReverLiteCounterProps> = ({
    min = 0,
    max,
    onUpdate
}) => {
    const [count, setCount] = useState(min ?? 0)
    const [direction, setDirection] = useState(0)
    const theme = useTheme()

    const increment = () => {
        if (max !== undefined && count >= max) return
        setDirection(1)
        setCount(count + 1)
        onUpdate && onUpdate(count + 1)
    }

    const decrement = () => {
        if (min !== undefined && count <= min) return
        setDirection(-1)
        setCount(count - 1)
        onUpdate && onUpdate(count - 1)
    }

    const variants = {
        enter: (direction: number) => ({
            y: -direction * 50,
            opacity: 0
        }),
        center: {
            y: 0,
            opacity: 1
        },
        exit: (direction: number) => ({
            y: direction * 50,
            opacity: 0
        })
    }

    return (
        <div className="flex items-center justify-center space-x-4 text-2xl">
            <button
                onClick={decrement}
                className="rounded text-xl"
                disabled={min && count <= min ? true : false}
            >
                <LessIcon
                    color={
                        count <= (min ?? 0)
                            ? undefined
                            : theme.colors.common.black
                    }
                />
            </button>
            <div className="flex w-2 items-center justify-center overflow-hidden">
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={count}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{ duration: 0.2 }}
                        className="absolute mx-2"
                    >
                        {count}
                    </motion.div>
                </AnimatePresence>
            </div>
            <button onClick={increment} className="rounded text-xl">
                <MoreIcon
                    color={
                        max && count >= max
                            ? undefined
                            : theme.colors.common.black
                    }
                />
            </button>
        </div>
    )
}

export default ReverLiteCounter

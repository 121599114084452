import { useNavigate as useNavigation } from 'react-router-dom'
import { useAppSelector } from '@/redux/hooks'
import { useSlug } from '@/hooks'

export function useNavigate() {
    const slug = useSlug()
    const navigate = useNavigation()
    const orderInfo = useAppSelector((store) => store.order)

    const navigateRelative = (path: string) => {
        navigate(path, { relative: 'path' })
    }
    const navigateAbsolute = (path: string) => {
        navigate(path)
    }

    const navigateBack = () => {
        navigate(-1)
    }

    const navigateLanding = () => {
        navigate(
            `/${slug}?orderNumber=${encodeURIComponent(
                orderInfo.orderNumber || ''
            )}&email=${encodeURIComponent(orderInfo.email || '')}`
        )
    }

    return { navigateRelative, navigateAbsolute, navigateLanding, navigateBack }
}

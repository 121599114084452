import styled from 'styled-components'
import { useTheme } from '@itsrever/design-system'
import BoxHeart from '@/assets/images/icons/BoxHeart'
import { useTranslate } from '@/hooks'
import { toRGB } from '@/utils'
import ExchangeIcon from '@/assets/images/icons/ExchangeIcon'
import { useAppDispatch } from '@/redux/hooks'
import { setKeepYourItem } from '@/redux/return/returnSlice'
import { useState } from 'react'

interface ExchangeKYICardsProps {
    isKYIForced: boolean
}

export const ExchangeKYICards: React.FC<ExchangeKYICardsProps> = ({
    isKYIForced
}) => {
    const dispatch = useAppDispatch()
    const { translate } = useTranslate()
    const theme = useTheme()
    const [kyiSelected, setKyiSelected] = useState(true)
    dispatch(setKeepYourItem(true))

    return (
        <>
            <div
                className="relative flex content-center items-center"
                onClick={() => {
                    dispatch(setKeepYourItem(true))
                    setKyiSelected(true)
                }}
            >
                <Card
                    selected={kyiSelected}
                    isBlueOption={true}
                    borderColor={theme.colors.primary.main}
                    bgColor={toRGB(theme.colors.primary.main)}
                >
                    <div className="flex items-center justify-between gap-2">
                        <div className="flex items-center gap-2">
                            <div className="opacity-60">
                                <BoxHeart />
                            </div>
                            <p className="text-lg">
                                <b>{translate(`kyi_title`)}</b>
                            </p>
                        </div>
                    </div>
                    <div>
                        <p
                            className="text-xs"
                            dangerouslySetInnerHTML={{
                                __html: translate(
                                    'kyi_refund_page_only_exchanges_keep_description'
                                )
                            }}
                        />
                    </div>
                </Card>
            </div>
            {!isKYIForced && (
                <div
                    className="relative flex content-center items-center"
                    onClick={() => {
                        dispatch(setKeepYourItem(false))
                        setKyiSelected(false)
                    }}
                >
                    <Card
                        selected={!kyiSelected}
                        isBlueOption={true}
                        borderColor={theme.colors.primary.main}
                        bgColor={toRGB(theme.colors.primary.main)}
                    >
                        <div className="flex items-center justify-between gap-2">
                            <div className="flex items-center gap-1">
                                <div className="size-8 opacity-60">
                                    <ExchangeIcon />
                                </div>
                                <p className="text-lg">
                                    <b
                                        dangerouslySetInnerHTML={{
                                            __html: translate(
                                                'kyi_refund_page_only_exchanges_return_title'
                                            )
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                        <div>
                            <p
                                className="text-xs"
                                dangerouslySetInnerHTML={{
                                    __html: translate(
                                        'kyi_refund_page_only_exchanges_return_description'
                                    )
                                }}
                            />
                        </div>
                    </Card>
                </div>
            )}
        </>
    )
}

export default ExchangeKYICards

interface CardProps {
    selected: boolean
    isBlueOption: boolean
    borderColor: string
    bgColor: string
}

const Card = styled.div<CardProps>`
    padding: ${(p) => (p.selected ? '12px 10px' : '14px 12px')};
    cursor: pointer;
    border: ${(p) => (p.selected ? '2px' : '0px')} solid;
    border-radius: 0.25rem;
    width: 100%;
    margin-bottom: 1.5rem;
    border-color: ${(p) =>
        p.selected ? (p.isBlueOption ? '#1b75eb' : p.borderColor) : ''};
    background-color: ${(p) =>
        p.selected
            ? p.isBlueOption
                ? 'rgb(27, 117, 235, 0.1)'
                : p.bgColor
            : ''};
    box-shadow: ${(p) =>
        p.selected ? '' : '0px 0px 2px 2px rgb(0 0 0 / 0.1)'};
`

import Confetti from 'react-confetti'
import styled from 'styled-components'
import { device } from '@/utils'
import { Button } from '@itsrever/design-system'
import { useTranslate, usePages } from '@/hooks'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '@/redux/hooks'
import { PbECommerceSettingsRefundExchangeFlowEnum } from '@itsrever/rever-api'
import { useEffect } from 'react'
import posthog from 'posthog-js'
import { captureEventPosthog } from '@/services/Posthog'
import { ExchangeFlows } from '@/utils/types'
import KYIInfoBanner from '@/components/KYIInfoBanner/KYIInfoBanner'

function SuccessTrackingPage() {
    const { translate } = useTranslate()
    const navigate = useNavigate()
    const { pages } = usePages()
    const settings = useAppSelector(
        (store) => store.settingsApi.settings.response
    )
    const webUrl = settings.WebsiteSettings?.url
    const [params] = useSearchParams()

    let processId =
        useAppSelector((store) => store.order.selectedProcessID) ?? ''
    processId ||= params.get('processId') ?? ''
    let orderNumber = useAppSelector((store) => store.order.orderNumber)
    orderNumber ||= params.get('orderNumber') ?? ''
    let email = useAppSelector((store) => store.order.email)
    email ||= params.get('email') ?? ''

    const confettiOff = useFeatureFlagEnabled('confettiOff')

    const isKeepYourItem = useAppSelector((s) => s.return.keepYourItem)

    const amount = params.get('amount')
    const currency = params.get('currency')
    const returnedItemsCount = params.get('returnedItemsCount')
    const exchangedOneOnOneItemsCount = params.get(
        'exchangedOneOnOneItemsCount'
    )
    const exchangedFullCatalogItemsCount = params.get(
        'exchangedFullCatalogItemsCount'
    )

    const exchangeFlow = toExchangeOptionExchangeFlow(
        settings.refund?.exchange_flow
    )

    useEffect(() => {
        if (!settings.is_testing_account) {
            if (email) {
                posthog?.identify(email)
            }
            if (!currency || !amount) return
            captureEventPosthog('Completed', {
                Amount: amount,
                Currency: currency,
                returnedItemsCount,
                exchangedOneOnOneItemsCount,
                exchangedFullCatalogItemsCount,
                exchangeFlow
            })
            params.delete(currency, amount)
        }
    }, [settings.is_testing_account])

    return (
        <MainDiv>
            <Card>
                {confettiOff ? null : (
                    <Confetti
                        initialVelocityY={20}
                        recycle={false}
                        id="confetti"
                    />
                )}
                <h3
                    dangerouslySetInnerHTML={{
                        __html: translate('success_title')
                    }}
                />

                <h6
                    className="mb-8 mt-2"
                    dangerouslySetInnerHTML={{
                        __html: isKeepYourItem
                            ? ''
                            : translate(`tracking_success_return_processed`) +
                              '.'
                    }}
                />

                {isKeepYourItem ? (
                    <p className="flex flex-col gap-4 pb-8">
                        <KYIInfoBanner />
                        <span
                            dangerouslySetInnerHTML={{
                                __html: translate(`kyi_success_click_button`)
                            }}
                        />
                    </p>
                ) : (
                    <p
                        className="pb-8"
                        dangerouslySetInnerHTML={{
                            __html: translate(`tracking_success_click_button`)
                        }}
                    />
                )}
                <Button
                    onClick={() =>
                        navigate(
                            `${pages.Tracking}?processId=${encodeURIComponent(
                                processId
                            )}&orderNumber=${encodeURIComponent(
                                orderNumber || ''
                            )}&email=${encodeURIComponent(
                                email || ''
                            )}&source=success_page`
                        )
                    }
                >
                    {isKeepYourItem
                        ? 'See process information'
                        : translate(`tracking_success_see_return_instructions`)}
                </Button>
                <p
                    className="pt-8"
                    dangerouslySetInnerHTML={{
                        __html: isKeepYourItem
                            ? ''
                            : translate(`tracking_success_receive_email`)
                    }}
                />
                {webUrl ? (
                    <Button
                        className="mt-10"
                        variant="text"
                        type="submit"
                        onClick={() => (window.location.href = webUrl)}
                    >
                        <p
                            className="underline underline-offset-4"
                            dangerouslySetInnerHTML={{
                                __html: translate('success_continue_shopping')
                            }}
                        />
                    </Button>
                ) : null}
            </Card>
        </MainDiv>
    )
}

const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: fit-content;
    min-height: 50%;
    background: #ffffff;
    margin-top: 1.5rem;
    padding: 1rem;
    @media ${device.lg} {
        margin-bottom: 1rem;
        padding: 1.5rem 2rem 1.5rem 2rem;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        margin-top: 2.5rem;
    }
`

const MainDiv = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    @media ${device.lg} {
        background-color: #f2f2f2;
        padding: 2rem;
    }
`

export default SuccessTrackingPage

function toExchangeOptionExchangeFlow(
    exchangeFlow: PbECommerceSettingsRefundExchangeFlowEnum | undefined
): (typeof ExchangeFlows)[keyof typeof ExchangeFlows] {
    switch (exchangeFlow) {
        case PbECommerceSettingsRefundExchangeFlowEnum.Open:
            return ExchangeFlows.Open
        case PbECommerceSettingsRefundExchangeFlowEnum.OpenWithGiftCard:
            return ExchangeFlows.OpenWithGiftCard
        case PbECommerceSettingsRefundExchangeFlowEnum.OneOnOne:
            return ExchangeFlows.OneOnOne
        case PbECommerceSettingsRefundExchangeFlowEnum.Full:
            return ExchangeFlows.Full
        default:
            return ExchangeFlows.None
    }
}
